import React,{ ReactElement } from "react";
import { SocialProductProps } from "./SocialProduct.types";
import { Image } from "@fluentui/react";
import { Label } from "src/ui";
import { localize } from "src/l10n";
import { Link } from "react-router-dom";

function SocialProduct(props: SocialProductProps): ReactElement {
    const { post } = props;
    if (!post) {
        return null;
    }

    const imageElement = !post.productImageUrl ? null : (
        <div className="product-image">
            <Image
                alt={post.name}
                src={post.productImageUrl} />
        </div>
    );

    return (
        <Link className="SocialProduct" to={`/products/${post.Id}`}>
            {imageElement}
            <div className="product-information">
                <Label
                    className="product-name"
                    color="dark-grey"
                    size="body-1"
                    weight="medium"
                >
                    {post.name}
                </Label>
                <Label
                    className="product-sku"
                    color="grey"
                    size="body-2"
                >
                    {localize("ARTICLE_ID")}: {post.articleId}
                </Label>
            </div>
        </Link>
    );
}

export default SocialProduct;
