import { Icon } from "@fluentui/react";
import React, { Component, ReactNode } from "react";
import { connect, MapDispatchToProps, MapStateToProps } from "react-redux";
import { Redirect, RouteComponentProps, withRouter } from "react-router";
import { ChatTabContext, IChatTabContext } from "src/chat/components/ChatTabContext";
import { MessageFetchType } from "src/chat/message-types";
import {
    BoundQueryMessagesHandler, getConversation, GetConversationHandler, markAsRead, MarkAsReadHandler, queryMessages,
    QueryMessagesHandler
} from "src/chat/redux";
import { SpintrUser, UnstyledButton } from "src/ui";
import { circleMedium, circleMediumSmall, circleSmall } from "src/ui/helpers/style";
import "./ConversationView.scss";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import Chat from "src/chat/components/new/Chat/Chat";
import SpintrLoader from "src/ui/components/Loader";
import { setSidebarDrawerId, setSidebarMode, VisageSidebarDrawerId, VisageSidebarMode } from "src/sidebar";
import { ConversationItemImage } from "src/chat/components/ConversationItemImage";

interface IRouteParams {
    conversationId: string;
}

interface IOwnProps extends RouteComponentProps<IRouteParams> { }

interface IStateProps {
    conversation: Spintr.IConversation;
    currentUser: Spintr.IUser;
    lastRead: { [messageId: number]: number[] };
    oldestMessageId?: number;
    dispatch?: any;
}

type Props = IOwnProps & IStateProps;

interface IState {
    isLoading: boolean;
    messageId?: number;
}

class ConversationView extends Component<Props, IState> {
    private chatContext: IChatTabContext;

    constructor(props: Props) {
        super(props);

        this.onReturnClick = this.onReturnClick.bind(this);
        this.markAsRead = this.markAsRead.bind(this);
        this.loadMore = this.loadMore.bind(this);

        this.chatContext = {
            clearUnread: this.markAsRead,
            currentUser: this.props.currentUser,
            lastRead: this.props.lastRead,
            loadMore: this.loadMore,
        };

        this.state = {
            isLoading: false,
            messageId: this.getMessageId(props)
        };
    }

    getMessageId(props) {
        const query = new URLSearchParams(props.location.search);
        const messageId = query.get('messageId');

        return messageId ? parseInt(messageId) : undefined;
    }

    componentDidMount(): void {
        this.props.dispatch(setSidebarMode(VisageSidebarMode.drawer));
        this.props.dispatch(setSidebarDrawerId(VisageSidebarDrawerId.groups));
    }

    public componentDidUpdate(prevProps): void {
        if (this.chatContext.lastRead !== this.props.lastRead) {
            this.chatContext.lastRead = this.props.lastRead;
        }
    }

    public render(): ReactNode {
        const conversationId = parseInt(this.props.match.params.conversationId, 10);
        if (!conversationId || isNaN(conversationId)) {
            return <Redirect to="/messages" />;
        }

        if (!this.props.conversation) {
            return <SpintrLoader />;
        }

        let imageUrl = "";

        if (this.props.conversation.imageUrl && this.props.conversation.imageUrl.length > 0) {
            imageUrl = this.props.conversation.imageUrl[0];
        }

        const blocked = this.props.conversation.participants.some((p) => p.blocked);
        const blockedBy = this.props.conversation.participants.some((p) => p.blockedBy);

        return (
            <div id="ConversationView" key={conversationId}>
                <div className="header">
                    <div className="conversation-info">
                        <div className="image">
                            <ConversationItemImage
                                conversation={this.props.conversation}
                                currentUserId={this.props.currentUser.id}
                                hideText={false} />
                        </div>
                    </div>
                </div>
                <Chat
                    scrollToMessageId={this.state.messageId}
                    conversationId={conversationId}
                    blocked={blocked}
                    blockedBy={blockedBy} />
            </div>
        );
    }

    protected loadMore(): void {
        if (this.state.isLoading) {
            return;
        }

        this.setState({ isLoading: true }, async () => {
            try {
                const promise = (this.props.dispatch(queryMessages) as BoundQueryMessagesHandler)({
                    conversationId: this.props.conversation.id,
                    fetchType: MessageFetchType.Messages,
                    take: 10,
                    maxId: this.props.oldestMessageId,
                });

                await promise;
            } catch (err) {
                console.log(err);
            } finally {
                this.setState({ isLoading: false });
            }
        });
    }

    protected markAsRead(): void {
        if (!this.props.conversation.unread) {
            return;
        }

        this.props.dispatch(markAsRead(
            this.props.conversation.id,
            this.props.conversation.unread,
        ));
    }

    protected onReturnClick(): void {
        this.props.history.push("/messages");
    }
}

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, Spintr.AppState> =
    (state, props) => {
        const conversation = state.chat.conversations.items.find(
            (c) => c.id === parseInt(props.match.params.conversationId, 10),
        );

        return {
            conversation,

            currentUser: {
                id: state.profile.active.id,
                imageUrl: state.profile.active.images.Message,
                info: "",
                name: state.profile.active.name,
                type: 1,
                typeName: "",
            },

            lastRead: !conversation || !conversation.lastRead ? {} : Object.keys(conversation.lastRead)
                .filter((k) => !isNaN(+k))
                .map((k) => parseInt(k, 10))
                .reduce((acc, userId) => {
                    const messageId = conversation.lastRead[userId];
                    if (!messageId) {
                        return acc;
                    }

                    acc[messageId] = acc[messageId] || [];
                    acc[messageId].push(userId);
                    return acc;
                }, {} as { [messageId: number]: number[] }),

            oldestMessageId: !conversation ? undefined : state.chat.messages.items
                .filter(m => m.conversationId === conversation.id)
                .reduce<number | undefined>((acc, m) => {
                    if (!acc) {
                        return m.id;
                    }

                    return m.id < acc ? m.id : acc;
                }, undefined),
        };
    };

const ConversationViewWithRouter = withRouter(ConversationView);
const ConnectedConversationViewWithRouter = connect(
    mapStateToProps, 
)(ConversationViewWithRouter);

export default ConnectedConversationViewWithRouter;