import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router";
import { StandardErrorBoundary, setVisage2FullscreenMode } from "src/ui";
import { ProductsListView } from "../ProductsListView";
import Visage2FullScreenHeader from "src/ui/components/Visage2FullScreenView/Visage2FullScreenHeader";
import { renderAdminTitle } from "src/admin/common/Title";
import { localize } from "src/l10n";
import { useDispatch, useSelector } from "react-redux";
import { ProductDetailsView } from "../ProductDetailsView";
import { debounce } from "src/utils";
import { setDisplaySearch } from "src/sidebar";
import classNames from "classnames";
import GlobalBreadcrumbs from "src/admin/views/GlobalBreadcrumbs";
import Visage2FullScreenHeaderSpacer from "src/ui/components/Visage2FullScreenView/Visage2FullScreenHeaderSpacer";
import { ProductsRootViewState } from "./ProductsRootView.types";
import { ProductFormDrawer } from "src/products/components";
import { Pivot, PivotItem } from "@fluentui/react";
import { ProductSettingsView } from "../ProductSettingsView";

function getCreateQueryString(queryString: string): string | undefined {
    const searchParams = new URLSearchParams(queryString);

    return searchParams.get("create");
}

function ProductsRootView(): ReactNode {
    const { pathname, search: queryString } = useLocation();
    const listRefreshRef = useRef<((() => void) | null)>(null);
    
    const dispatch = useDispatch();

    const [state, setState] = useState<ProductsRootViewState>({
        showCreationDrawer: !!getCreateQueryString(queryString),
    });

    const closeCreationDrawer = useCallback(() => {
        setState((prevState) => ({
            ...prevState,
            showCreationDrawer: false,
        }));

        if (!listRefreshRef.current) {
            return;
        }

        listRefreshRef.current();
    }, [setState, listRefreshRef]);

    const openCreationDrawer = useCallback(() => setState((prevState) => ({
        ...prevState,
        showCreationDrawer: true,
    })), [setState]);

    const userRoles = useSelector<Spintr.AppState, string[]>(
        (state) => state.profile.active.roles,
    );

    const isAdministrativeView = pathname.startsWith("/admin/");

    const breadcrumbs = useMemo<JSX.Element>(() => {
        if (isAdministrativeView) {
            return null;
        }

        const pattern = /\/products\/\d+$/gmi;
        if (pattern.test(pathname)) {
            return null;
        }

        return <GlobalBreadcrumbs />;
    }, [isAdministrativeView, pathname]);


    const adminTitle = isAdministrativeView
        ? renderAdminTitle(localize("PRODUCTS"))
        : null;

    if (isAdministrativeView && !userRoles.includes("administrators")) {
        return <Redirect to="/products" />;
    }

    const prefix = isAdministrativeView ? "/admin" : "";
    const routing = (
        <Switch>
            <Route path={prefix + "/products/:productId"}>
                <ProductDetailsView />
            </Route>
            <Route path={prefix + "/products"} exact={true}>
                <ProductsListView listRefreshRef={listRefreshRef} openCreationDrawer={openCreationDrawer} />
            </Route>
        </Switch>
    );

    const content = !isAdministrativeView ? routing : (
        <Pivot>
            <PivotItem headerText={localize("PRODUCTS")}>
                {routing}
            </PivotItem>
            <PivotItem headerText={localize("Installningar")}>
                <ProductSettingsView />
            </PivotItem>
        </Pivot>
    );

    return (
        <StandardErrorBoundary>
            <div id="ProductsRootView" className={classNames({
                "general-view": !isAdministrativeView,
                "list-view": pathname === "/products" || pathname === "/admin/products",
            })} role={isAdministrativeView ? undefined : "main"}>
                {adminTitle}
                <div className="products-view">
                    {breadcrumbs}
                    {content}
                </div>
            </div>

            <ProductFormDrawer
                initialArticleId={getCreateQueryString(queryString)}
                onDismiss={closeCreationDrawer}
                open={state.showCreationDrawer} />
        </StandardErrorBoundary>
    )
}

export default ProductsRootView;
