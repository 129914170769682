import {
    CLEAR_ATTACHED_FILES_AND_FOLDERS,
    FETCH_ATTACHED_FILE,
    FETCH_ATTACHED_FOLDER,
    REMOVE_ATTACHED_FILE,
    REMOVE_ATTACHED_FOLDER
} from "./page-action-types";

import api from "src/spintr/SpintrApi";

const baseUrl = "/api/files";

const fetchAttachedFolder = (folder, source, take: number = 1000) => ({
    meta: { folder },
    payload: api.get(baseUrl, {
        params: {
            folderId: folder.id,
            source: source,
            take
        },
    }),
    type: FETCH_ATTACHED_FOLDER,
});


const fetchAttachedFile = (id, source?) => ({
    meta: { id },
    payload: api.get(`${baseUrl}/${id}`, {
        params: {
            source: source,
        },
    }),
    type: FETCH_ATTACHED_FILE,
});

const clearAttachedFilesAndFolders = () => ({
    type: CLEAR_ATTACHED_FILES_AND_FOLDERS
})

const removeAttachedFile = (id: number) => ({
    meta: { id },
    type: REMOVE_ATTACHED_FILE
})

const removeAttachedFolder = (id: number) => ({
    meta: { id },
    type: REMOVE_ATTACHED_FOLDER
})

export {
    fetchAttachedFolder,
    fetchAttachedFile,
    clearAttachedFilesAndFolders,
    removeAttachedFile,
    removeAttachedFolder
};