import React, { FunctionComponent } from "react";
import Text from "./Text";
import {
    Colors,
    LetterSpacings,
    LineHeights,
    TextProps as Props,
    TextSizes,
    TextWeight,
} from "./Text.types";

type TextPresetProps = {
    letterSpacing:      LetterSpacings | undefined;
    lineHeight:         LineHeights | undefined;
    size:               TextSizes | undefined;
    weight:             TextWeight | undefined;
    color?:             Colors | undefined;
}

type PresetGenerator = (preset: TextPresetProps) => FunctionComponent<Props>;
const withPreset: PresetGenerator = (preset) => (props) => (
    <Text
        {...props}
        size={props.size || preset.size}
        weight={props.weight || preset.weight}
        lineHeight={props.lineHeight || preset.lineHeight}
        letterSpacing={props.letterSpacing || preset.letterSpacing}
        color={props.color || preset.color}
    >
        {props.children}
    </Text>
);

export const ExtraLargeHeading: FunctionComponent<Props> = withPreset({
    letterSpacing: "normal",
    lineHeight: "narrow",
    size: "headingExtraLarge",
    weight: "semiBold"
});

export const LargeHeading: FunctionComponent<Props> = withPreset({
    letterSpacing: "normal",
    lineHeight: "narrow",
    size: "headingLarge",
    weight: "semiBold"
});

export const SmallHeading: FunctionComponent<Props> = withPreset({
    letterSpacing: "normal",
    lineHeight: "narrow",
    size: "headingSmall",
    weight: "semiBold"
});

export const SemiboldHeading: FunctionComponent<Props> = withPreset({
    letterSpacing: "normal",
    lineHeight: "low",
    size: "headingSmall",
    weight: "semiBold"
});

export const Heading: FunctionComponent<Props> = withPreset({
    letterSpacing: "normal",
    lineHeight: "low",
    size: "headingSmall",
    weight: "medium"
});

export const LargeBody: FunctionComponent<Props> = withPreset({
    letterSpacing: "normal",
    lineHeight: "normal",
    size: "bodyLarge",
    weight: "medium"
});

export const MediumBodyBolder: FunctionComponent<Props> = withPreset({
    letterSpacing: "normal",
    lineHeight: "higher",
    size: "bodyMedium",
    weight: "medium"
});

export const MediumBody: FunctionComponent<Props> = withPreset({
    letterSpacing: "normal",
    lineHeight: "higher",
    size: "bodyMedium",
    weight: "regular"
});

export const SmallBodyBolder: FunctionComponent<Props> = withPreset({
    letterSpacing: "dense",
    lineHeight: "high",
    size: "bodySmall",
    weight: "medium"
});

export const SmallBodyProps: TextPresetProps = {
    letterSpacing: "dense",
    lineHeight: "high",
    size: "bodySmall",
    weight: "regular"
};

export const SmallBody: FunctionComponent<Props> = withPreset(SmallBodyProps);

export const ErrorText: FunctionComponent<Props> = withPreset({
    ...SmallBodyProps,
    color: "pink",
});

export const CaptionBolder: FunctionComponent<Props> = withPreset({
    letterSpacing: "dense",
    lineHeight: "normal",
    size: "caption",
    weight: "medium"
});

export const CaptionBody: FunctionComponent<Props> = withPreset({
    letterSpacing: "dense",
    lineHeight: "normal",
    size: "caption",
    weight: "regular"
});
