import React, { ReactElement, useCallback, useEffect, useState } from "react";
import {
    ProductSuggestionsProps as Props,
    EditorStateChangeHandler,
    ProductSuggestionsState,
    ProductSuggestion as Suggestion,
    CompletedProductSuggestion,
} from "./ProductSuggestions.types";
import Axios from "axios";
import { findProductBySkuAsync } from "src/products/productsApi";
import { ProductSuggestionsEntry } from "../ProductSuggestionsEntry";
import { Product } from "src/products/types";
import { EditorState, Modifier, SelectionState } from "draft-js";
import { ProductPluginState, ProductRemovedHandler } from "../types";

type Leaf = {
    start:      number;
    end:        number;
};

type Decorator = {
    decoratorKey:   string;
    start:          number;
    end:            number;
    leaves:         Leaf[];
};

type ProductOccurence = {
    anchorKey:      string;
    anchorOffset:   number;
    decoratorKey:   string;
    start:          number;
    end:            number;
    text:           string;
};

type StateSetter = React.Dispatch<React.SetStateAction<ProductSuggestionsState>>;

function addProductToEditor(
    editorState: EditorState,
    product: Product,
    entityMutability: "SEGMENTED" | "IMMUTABLE" | "MUTABLE"
): EditorState {
    const currentContent = editorState
        .getCurrentContent();

    const contentStateWithEntity = currentContent
        .createEntity("PRODUCT", entityMutability, {
            product,
        });

    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    const currentSelectionState = editorState.getSelection();
    const anchorKey = currentSelectionState.getAnchorKey();
    const currentBlock = currentContent.getBlockForKey(anchorKey);
    const blockText = currentBlock.getText();

    const start = blockText.indexOf(product.articleId);
    const end = start + product.articleId.length;

    const productTextSelection = currentSelectionState.merge({
        anchorOffset: start,
        focusOffset: end,
    });

    let productReplacedContent = Modifier.replaceText(
        currentContent,
        productTextSelection,
        product.articleId,
        editorState.getCurrentInlineStyle(),
        entityKey,
    );

    const blockKey = productTextSelection.getAnchorKey();
    const blockSize = currentContent
        .getBlockForKey(blockKey)
        .getLength();

    if (blockSize === end) {
        productReplacedContent = Modifier.insertText(
            productReplacedContent,
            productReplacedContent.getSelectionAfter(),
            " ",
        );
    }

    const updatedEditorState = EditorState.push(
        editorState,
        productReplacedContent,
        "insert-fragment",
    );

    return EditorState.forceSelection(
        updatedEditorState,
        productReplacedContent.getSelectionAfter(),
    );
}

function updateEditorStateWithProduct(product: Product, pluginState: ProductPluginState): void {
    const updatedEditorState = addProductToEditor(
        pluginState.getEditorState(),
        product,
        "SEGMENTED",
    );

    pluginState.setEditorState(updatedEditorState);
}

function mapOccurenceToPromise(
    product: ProductOccurence,
    setState: StateSetter,
    pluginState: ProductPluginState,
    onAdded?: Props["onAddProduct"],
): Suggestion {    
    const cancelTokenSource = Axios.CancelToken.source();

    const promise = findProductBySkuAsync(product.text, cancelTokenSource.token)
        .then((response) => {
            setState((prevState) => ({
                ...prevState,
                products: {
                    ...prevState.products,
                    [response.articleId]: {
                        state: "Completed",
                        product: response,
                        sku: response.articleId,
                    },
                },
            }));

            updateEditorStateWithProduct(response, pluginState);

            onAdded?.(response);
        })
        .catch(() => {
            setState((prevState) => ({
                ...prevState,
                products: {
                    ...prevState.products,
                    [product.text]: {
                        state: "NotFound",
                        sku: product.text,
                    },
                },
            }));
        });

    return {
        sku: product.text,
        state: "Pending",
        cancelTokenSource,
        promise,
    }
}

function ProductSuggestions(props: Props): ReactElement {
    const {
        editorStateChangeRef,
        productRemovedRef,
        onAddProduct,
        onRemoveProduct,
        state: pluginState,
    } = props;

    const [ state, setState ] = useState<ProductSuggestionsState>({
        products: {},
    });

    const onEditorStateChange = useCallback<EditorStateChangeHandler>(
        (editorState) => {
            const mentions = pluginState.getMentions();
            if (mentions.length === 0) {
                return editorState;
            }

            const selection = editorState.getSelection();
            if (!selection.isCollapsed() || !selection.getHasFocus()) {
                return editorState
            }

            const offsetDetails = mentions.map((offsetKey) => {
                const [blockKey, decoratorKey, leafKey] = offsetKey.split("-");

                return {
                    blockKey,
                    decoratorKey: parseInt(decoratorKey, 10),
                    leafKey: parseInt(leafKey, 10),
                };
            });

            const anchorKey = selection.getAnchorKey();
            const anchorOffset = selection.getAnchorOffset();

            const leaves = offsetDetails
                .filter((offsetDetail) => offsetDetail.blockKey === anchorKey)
                .map((offsetDetail) => editorState
                    .getBlockTree(offsetDetail.blockKey)
                    .getIn([offsetDetail.decoratorKey]) as Decorator,
                );

            if (leaves.every((leaf) => leaf === undefined)) {
                return editorState;
            }

            const blockText = editorState
                .getCurrentContent()
                .getBlockForKey(anchorKey)
                .getText();
                
            const products: ProductOccurence[] = leaves
                .filter((leaf) => leaf !== undefined)
                .map((leaf) => ({
                    anchorKey,
                    anchorOffset,
                    decoratorKey: leaf.decoratorKey,
                    end: leaf.end,
                    start: leaf.start,
                    text: blockText.slice(leaf.start, leaf.end),
                }))

            if (products.length === 0) {
                return editorState;
            }
            
            setState((prevState) => ({
                ...prevState,
                products: Object
                    .keys(prevState.products)
                    .map((sku) => prevState.products[sku])
                    .concat(products
                        .filter((product) => !prevState.products.hasOwnProperty(product.text))
                        .map((product) => mapOccurenceToPromise(
                            product,
                            setState,
                            pluginState,
                            onAddProduct,
                        ))
                    )
                    .reduce((acc, product) => {
                        acc[product.sku] = product;
                        return acc;
                    }, {}),
            }));

            return editorState;
        },
        [pluginState, setState, onAddProduct],
    );

    const onSuggestionDismissed = useCallback(
        (product: Product) => {
            if (!pluginState.getEditorState || !pluginState.setEditorState) {
                return;
            }

            const suggestion = state.products[product.articleId];
            if (!suggestion || suggestion.state !== "Completed") {
                return;
            }
            
            const editorState = pluginState.getEditorState();
            const contentState = editorState.getCurrentContent();

            const entities: { blockKey: string, start: number, end: number }[] = [];

            for (const block of contentState.getBlocksAsArray()) {
                block.findEntityRanges(
                    (character) => !!character.getEntity(),
                    (start, end) => {
                        const entityKey = block.getEntityAt(start);
                        if (!entityKey) {
                            return null;
                        }

                        const entity = contentState.getEntity(entityKey);
                        if (!entity || entity.getType() !== "PRODUCT") {
                            return null;
                        }

                        const { product: entityProduct } = entity.getData();
                        if (entityProduct?.articleId !== product.articleId) {
                            return null;
                        }

                        entities.push({ blockKey: block.getKey(), start, end });
                    },
                );
            }

            const newContentState = entities
                .reduce((currentContent, entity) => {
                    const selection = SelectionState.createEmpty(entity.blockKey)
                        .merge({
                            anchorOffset: entity.start,
                            focusOffset: entity.end,
                        });

                    return Modifier.applyEntity(
                        currentContent,
                        selection,
                        null,
                    );
                }, contentState);
          
            const newEditorState = EditorState.push(
                editorState,
                newContentState,
                "apply-entity",
            );

            pluginState.setEditorState(newEditorState);

            setState((prevState) => ({
                ...prevState,
                products: Object.keys(prevState.products)
                    .map((sku) => {
                        const match = prevState.products[sku];
                        if (sku !== product.articleId) {
                            return match;
                        }

                        if (match.state !== "Completed") {
                            return undefined;
                        }

                        return {
                            ...match,
                            state: "Dismissed",
                        };
                    })
                    .filter((item) => !!item)
                    .reduce((acc, item) => {
                        acc[item.sku] = item;
                        return acc;
                    }, {}),
            }));

            onRemoveProduct?.(product);
        },
        [state, pluginState, setState, onRemoveProduct],
    );

    const onProductRemoved = useCallback<ProductRemovedHandler>(
        (product) => {
            setState((prevState) => ({
                ...prevState,
                products: {
                    ...prevState.products,
                    [product.articleId]: {
                        product,
                        sku: product.articleId,
                        state: "Dismissed",
                    },
                },
            }));

            onRemoveProduct?.(product);
        },
        [onRemoveProduct, setState],
    );

    useEffect(() => {
        editorStateChangeRef.current = onEditorStateChange;
        productRemovedRef.current = onProductRemoved;

        return () => {
            if (editorStateChangeRef.current === onEditorStateChange) {
                editorStateChangeRef.current = null;
            }

            if (productRemovedRef.current === onProductRemoved) {
                productRemovedRef.current = null;
            }

        };
    }, [editorStateChangeRef, productRemovedRef, onEditorStateChange, onProductRemoved]);

    const products = Object
        .values(state.products)
        .filter((product) => product.state === "Completed") as CompletedProductSuggestion[];

    if (products.length === 0) {
        return null;
    }

    return (
        <div className="ProductSuggestions">
            {products.map((product) => (
                <ProductSuggestionsEntry
                    key={product.sku}
                    onDismiss={onSuggestionDismissed}
                    product={product.product}
                />
            ))}
        </div>
    );
}

export default ProductSuggestions;
