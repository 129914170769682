import React, { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { localize } from "src/l10n";
import { Breadcrumbs, UnstyledButton } from "src/ui";
import { FormControl, FormSection, FormFooterBar } from "src/ui/components/Forms";
import { ArticleIdModel, ArticleIdPatternProps, ProductSettingsViewState } from "./ProductSettingsView.types";
import SuccessMessagebar from "src/ui/components/Messagebars/SuccessMessagebar";
import SpintrLoader from "src/ui/components/Loader";
import Axios from "axios";
import productsApi from "src/products/productsApi";
import { Dropdown, IDropdownOption, TextField } from "@fluentui/react";
import { randexp } from "randexp";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { CaptionBolder } from "src/components";

function getPredefinedOptions() {
    return [{
        key: "(\\d{6})",
        text: localize("6_DIGITS"),
    }, {
        key: "(\\d{5}\\-\\d{1})",
        text: localize("5_DIGITS_DASH_1_DIGIT"),
    }, {
        key: "(\\d{8})",
        text: localize("8_DIGITS"),
    }, {
        key: "([A-Z]{3}\\d{3})",
        text: localize("3_LETTERS_3_DIGITS"),
    }, {
        key: "([A-Z]{2}\\-\\d{4})",
        text: localize("2_LETTERS_DASH_4_DIGITS"),
    }, {
        key: "(\\d{12})",
        text: localize("12_DIGITS"),
    }, {
        key: "(\\d{4}\\-\\d{3})",
        text: localize("4_DIGITS_DASH_3_DIGITS"),
    }, {
        key: "([A-Z]{3}\\d{4}[A-Z]{1})",
        text: localize("3_LETTERS_4_DIGITS_1_LETTER"),
    }, {
        key: "(\\d{2}\\-\\d{4}\\-\\d{2})",
        text: localize("2_DIGITS_DASH_4_DIGITS_DASH_2_DIGITS"),
    }];
}

function ArticleIdPattern({
    model,
    options,
    index,
    onOptionChanged,
    onOptionRemoved,
}: ArticleIdPatternProps): ReactElement {
    const onDropdownChanged = useCallback((_: unknown, option: IDropdownOption) => {
        onOptionChanged({
            key: option.key as string,
            value: option.key as string,
            isValid: true,
        }, index);
    }, [index, onOptionChanged]);

    const onTextChanged = useCallback(
        function _psRowChanged(ev: React.ChangeEvent<HTMLInputElement>) {
            const { value } = ev.target;

            let isValid = false;
            try {
                new RegExp(value);
                isValid = true;
            } catch (_) {}

            onOptionChanged({
                key: "",
                value,
                isValid,
            }, index);
        },
        [index],
    );

    const onRemoveClicked = useCallback(
        function _psRowRemove() {
            onOptionRemoved(index);
        },
        [index, onOptionRemoved],
    );

    return (
        <div className="ArticleIdPattern">
            <FormControl className="pattern-control">
                <UnstyledButton className="remove-pattern" onClick={onRemoveClicked}>
                    <Visage2Icon icon="trash" />
                </UnstyledButton>
                <Dropdown
                    dropdownWidth="auto"
                    onChange={onDropdownChanged}
                    options={options}
                    selectedKey={model.key} />
                {model.key === "" && (
                    <div className="custom-pattern">
                        <TextField
                            errorMessage={model.isValid
                                ? undefined
                                : localize("VALIDATION_INVALID_FORMAT_FOR_FIELDNAME")}
                            onChange={onTextChanged}
                            value={model.value} />
                        <div className="example">
                            {model.isValid && (
                                <span className="example">
                                    {localize("EXAMPLE")}: {randexp(model.value, "gmi")}
                                </span>
                            )}
                        </div>
                    </div>
                )}
            </FormControl>
        </div>
    );
}

function ProductSettingsView(): ReactElement {
    const [state, setState] = useState<ProductSettingsViewState>({
        isWaiting:          false,
        patterns:           [],
        savedSuccessfully:  false,
    });

    const breadcrumbs = useMemo(() => {
        const basePath = "/admin";

        return [{
            key: "1",
            text: localize("Administration"),
            link: basePath,
        }, {
            key: "2",
            text: localize("PRODUCTS"),
            link: basePath + "/products",
        }];
    }, []);

    const dropdownOptions = useMemo(
        () => getPredefinedOptions()
            .map<IDropdownOption>((item) => ({
                key: item.key,
                title: `${item.text} (${randexp(item.key, "gmi")})`,
                text: `${item.text} (${randexp(item.key, "gmi")})`,
                data: item.key,
            })).concat({
                key: "",
                text: localize("CUSTOM_PATTERN_REGEX"),
                title: localize("CUSTOM_PATTERN_REGEX"),
                data: "",
            }),
        [],
    );

    const onOptionChanged = useCallback((model: ArticleIdModel, idx: number) => {
        setState((s) => ({
            ...s,
            patterns: s.patterns.map((pattern, index) => {
                if (index === idx) {
                    return model;
                }

                return pattern;
            }),
        }));
    }, [setState]);

    const onAddPatternClicked = useCallback(
        function _ProductSettingsAddPattern() {
            setState((s) => ({
                ...s,
                patterns: s.patterns.concat({
                    key: "",
                    value: "",
                    isValid: false,
                }),
            }));
        },
        [setState],
    );

    const onOptionRemoved = useCallback(
        function _ProductSettingsRemovePattern(index: number) {
            setState((s) => ({
                ...s,
                patterns: s.patterns.filter((_, idx) => idx !== index),
            }));
        },
        [setState],
    );

    const onSaveClicked = useCallback(async () => {
            if (state.isWaiting) {
                return;
            }

            setState((s) => ({
                ...s,
                isWaiting: true,
            }));

            try {
                const settings = await productsApi.updateSettingsAsync({
                    articleIdPatterns: state.patterns.map((pattern) => pattern.value),
                });

                const patterns = getPredefinedOptions();

                setState((s) => ({
                    ...s,
                    isWaiting: false,
                    savedSuccessfully: true,
                    patterns: settings.articleIdPatterns.map((pattern) => ({
                        key: patterns.find((p) => p.key === pattern)
                            ? pattern
                            : "",
                        value: pattern,
                        isValid: true,
                    })),
                }));
            } catch (_) {
                setState((s) => ({
                    ...s,
                    isWaiting: false,
                }));
            }
        },
        [state.isWaiting, state.patterns, setState],
    );


    useEffect(function _ProductSettingsViewMount() {
        const cts = Axios.CancelToken.source();

        setState((s) => ({
            ...s,
            isWaiting: true,
        }));

        productsApi
            .getSettingsAsync(cts.token)
            .then((settings) => {
                const patterns = getPredefinedOptions();

                setState((s) => ({
                    ...s,
                    isWaiting: false,
                    patterns: (settings.articleIdPatterns.length === 0
                        ? [""]
                        : settings.articleIdPatterns)
                        .map((pattern) => ({
                            key: patterns.find((p) => p.key === pattern)
                                ? pattern
                                : "",
                            value: pattern,
                            isValid: true,
                        })),
                }));
            })
            .catch((_) => {

            });

        return function _ProductSettingsViewUnmount() {
            cts.cancel();
        }
    }, [setState]);
    
    return (
        <div id="ProductSettingsView">
            <Breadcrumbs
                useGlobalBreadcrumbs={true}
                displayInstance={true}
                items={breadcrumbs}
            />
            {state.isWaiting ? <SpintrLoader /> : (<>
                {state.savedSuccessfully && (
                    <SuccessMessagebar
                        message={localize("Klar")}
                        onDismiss={() => {
                            setState((s) => ({
                                ...s,
                                savedSuccessfully: false
                            }));
                        }}
                    />
                )}
                <form>
                    <FormSection title={localize("GOOGLE_MERCHANT_IMPORT")}>
                        <CaptionBolder color="grayDark">
                            {localize("GOOGLE_MERCHANT_INFO")}
                        </CaptionBolder>
                    </FormSection>
                    <FormSection title={localize("ARTICLE_ID_FORMAT")}>
                        <CaptionBolder color="grayDark">
                            {localize("ARTICLE_ID_FORMAT_INFO")}
                        </CaptionBolder>
                        <FormControl>
                            {state.patterns.map((model, idx) => (
                                <ArticleIdPattern
                                    key={model.key + idx}
                                    index={idx}
                                    model={model}
                                    onOptionChanged={onOptionChanged}
                                    onOptionRemoved={onOptionRemoved}
                                    options={dropdownOptions}/>
                            ))}
                            <div className="add-pattern-row">
                                <UnstyledButton
                                    className="add-pattern"
                                    onClick={onAddPatternClicked}
                                >
                                    {localize("ADD_PATTERN")}
                                </UnstyledButton>
                            </div>
                        </FormControl>
                    </FormSection>
                    <FormFooterBar
                        disableSaveButton={
                            state.patterns.some((pattern) => !pattern.isValid)
                            || state.isWaiting}
                        hideCancelButton={true}
                        onSaveClick={onSaveClicked}
                        />
                </form>
            </>)}
        </div>

    );
}

export default ProductSettingsView;
