import Axios, { AxiosError, CancelToken } from "axios";
import api from "src/spintr/SpintrApi";
import { Product, ProductModel, ProductSettingsModel } from "./types";
import qs from "qs";

const baseUrl = "/api/v1/products";

export async function queryProductsAsync(
    offset: number = 0,
    limit: number = 10,
    query?: string | undefined,
    orderBy: string = "name",
    orderAscending: boolean = true,
    cancelToken?: CancelToken | undefined,
): Promise<Spintr.QueryEnvelope<Product>> {
    try {
        const response = await api.get<Spintr.QueryEnvelope<Product>>(
            baseUrl,
            {
                params: {
                    offset,
                    limit,
                    query,
                    orderBy,
                    orderAscending,
                },
                cancelToken,
            },
        );

        return response.data;
    
    } catch (error) {
        const axiosError = error as AxiosError;
        if (!axiosError.isAxiosError) {
            throw error;
        }

        // TODO: Handle error in some way?

        throw error;
    }
}

export async function queryProductsByIdsAsync(
    productIds: number[],
    cancelToken?: CancelToken | undefined,
): Promise<Spintr.QueryEnvelope<Product>> {
    try {
        const response = await api.get<Spintr.QueryEnvelope<Product>>(
            `${baseUrl}/batch`,
            {
                cancelToken,
                params: {
                    productIds,
                },
                paramsSerializer: (params) => qs.stringify(params, {
                    arrayFormat: "indices",
                }),
            },
        );

        return response.data;
    
    } catch (error) {
        const axiosError = error as AxiosError;
        if (!axiosError.isAxiosError) {
            throw error;
        }

        // TODO: Handle error in some way?

        throw error;
    }
}

export async function findProductAsync(productId: number, cancelToken?: CancelToken | undefined): Promise<Product> {
    try {
        const response = await api.get<Product>(`${baseUrl}/${productId}`, { cancelToken });

        return response.data;
    }
    catch (error) {
        const axiosError = error as AxiosError;
        if (!axiosError.isAxiosError) {
            throw error;
        }

        // TODO: Deal with this error in some way?
        throw error;
    }
}

export async function findProductBySkuAsync(sku: string, cancelToken?: CancelToken | undefined): Promise<Product> {
    try {
        const response = await api.get<Product>(`${baseUrl}/sku/${sku}`, { cancelToken });

        return response.data;
    }
    catch (error) {
        const axiosError = error as AxiosError;
        if (!axiosError.isAxiosError) {
            throw error;
        }

        // TODO: Deal with this error in some way?
        throw error;
    }
}

export async function createProductAsync(
    model: ProductModel,
    linkExisting?: boolean | undefined,
    cancelToken?: CancelToken | undefined,
): Promise<Product> {
    try {
        const response = await api.post<Product>(
            baseUrl,
            model,
            { cancelToken, params: {
                linkExistingPosts: linkExisting,
            }},
        );

        return response.data;
    }
    catch (error) {
        const axiosError = error as AxiosError;
        if (!axiosError.isAxiosError) {
            throw error;
        }

        // TODO: Handle error in some way?

        throw axiosError.response?.data;
    }
}

export async function updateProductAsync(
    productId: number,
    model: ProductModel & { status: Spintr.ContentStatus },
    cancelToken?: CancelToken | undefined,
): Promise<Product> {
    try {
        const response = await api.put<Product>(
            `${baseUrl}/${productId}`,
            model,
            { cancelToken},
        );

        return response.data;
    }
    catch (error) {
        const axiosError = error as AxiosError;
        if (!axiosError.isAxiosError) {
            throw error;
        }

        // TODO: Handle error in some way?

        throw axiosError.response?.data;
    }
}

export async function deleteProductAsync(
    productId: number,
    cancelToken?: CancelToken | undefined,
): Promise<void> {
    try {
        await api.delete<never>(
            `${baseUrl}/${productId}`,
            { cancelToken},
        );
    }
    catch (error) {
        const axiosError = error as AxiosError;
        if (!axiosError.isAxiosError) {
            throw error;
        }

        // TODO: Handle error in some way?

        throw error;
    }
}

export async function queryPostsAsync(
    productId:      number,
    offset:         number = 0,
    limit:          number = 10,
    cancelToken?:   CancelToken | undefined,
): Promise<Spintr.QueryEnvelope<Spintr.ISocialPostBase>> {
    try {
        const response = await api.get<Spintr.QueryEnvelope<Spintr.ISocialPostBase>>(
            `/api/v1/products/${productId}/posts`,
            {
                cancelToken,
                params: {
                    offset,
                    limit,
                },
            }
        );

        return response.data;
    } catch (error) {
        const axiosError = error as AxiosError;
        if (!axiosError.isAxiosError) {
            throw error;
        }

        // TODO: Deal with this error in some way?
        throw error;
    }
}

export async function getProductSettingsAsync(cancelToken?: CancelToken): Promise<ProductSettingsModel> {
    try {
        const response = await api.get<ProductSettingsModel>(
            `${baseUrl}/settings`,
            { cancelToken },
        );

        return response.data;
    }
    catch (error) {
        const axiosError = error as AxiosError;

        if (!axiosError.isAxiosError) {
            throw error;
        }

        // TODO: Deal with this error in some way?
        throw axiosError;
    }
}

export async function updateProductSettingsAsync(
    model: ProductSettingsModel,
): Promise<ProductSettingsModel> {
    try {
        const response = await api.put<ProductSettingsModel>(`${baseUrl}/settings`, model);

        return response.data;
    }
    catch (error) {
        const axiosError = error as AxiosError;

        if (!axiosError.isAxiosError) {
            throw error;
        }

        // TODO: Deal with this error in some way?
        throw axiosError;
    }
}

const productsApi = {
    queryAsync:             queryProductsAsync,
    queryByIdsAsync:        queryProductsByIdsAsync,
    findAsync:              findProductAsync,
    findBySkuAsync:         findProductBySkuAsync,
    createAsync:            createProductAsync,
    updateAsync:            updateProductAsync,
    deleteAsync:            deleteProductAsync,
    queryPostsAsync,
    getSettingsAsync:       getProductSettingsAsync,
    updateSettingsAsync:    updateProductSettingsAsync,

    cancelTokenSource:  () => Axios.CancelToken.source(),
};

export default productsApi;
