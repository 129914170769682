import axios, { AxiosResponse } from 'axios';
import classnames from 'classnames';
import dompurify from 'dompurify';
import {
    CommandBar,
    CommandBarButton,
    ICommandBarItemProps,
    Icon,
    Modal,
    SearchBox,
    Selection
} from '@fluentui/react';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { RouteChildrenProps, withRouter } from 'react-router';
import { localize } from 'src/l10n';
import { ActionMenu, Breadcrumbs, Label, Loader, PageHeader, UnstyledButton } from 'src/ui';
import CustomDialog from 'src/ui/components/Dialogs/CustomDialog';
import SpintrList from 'src/ui/components/SpintrList/SpintrList';
import TinyFormattedContent from 'src/ui/components/Tiny/displayment/TinyFormattedContent';
import { debounce, isAnythingDirty } from 'src/utils';
import { pdf, print } from 'src/utils/print';
import { truncate } from 'src/utils/truncate';
import NotesEditView from './NotesEditView';
import './NotesView.scss';
import api from 'src/spintr/SpintrApi';
import SpintrSearch from 'src/ui/components/SpintrList/SpintrSearch';
import PopupHeader from 'src/ui/components/PopupHeader';
import Visage2Icon from 'src/visage2/Visage2Icon/Visage2Icon';
import { IContentHeaderButton } from 'src/ui/components/PageHeader';
import classNames from 'classnames';
import { SpintrTypes } from 'src/typings';

interface IPageRouteParams {
    noteId: any;
}

interface Props extends RouteChildrenProps<IPageRouteParams> {
    isSmallViewMode: boolean;
    viewMode: Spintr.ViewMode;
    instanceName: string;
    targetId?: number;
}

export interface IDetailsListBasicExampleItem {
    key: number;
    name: string;
    value: number;
}

interface State {
    isLoading: boolean;
    searchExpanded: boolean;
    searchText: string;
    showModal: boolean;
    showDeleted: boolean;
    isAscending: boolean;
    note: any;
    selectionDetails: any;
    selection: any;
    listMode: boolean;
    notes: any[];
    selectMode: boolean;
    selected: any[];
    saveError: string[];
    hasMadeInitialShowModal: boolean;
    categories: any[];
    categoryId: number;
    displayUnsavedChangesPopup: boolean;
}

class NotesView extends Component<Props, State> {
    private listRef = React.createRef<SpintrList>();
    private _selection: Selection;

    constructor(props) {
        super(props);

        this._selection = new Selection({
            onSelectionChanged: () => this.setState({ selectionDetails: this._getSelectionDetails() }),
        });
    }

    state = {
        searchExpanded: true,
        searchText: "",
        showModal: false,
        showDeleted: false,
        isAscending: false,
        note: {} as any,
        selectionDetails: undefined,
        selection: undefined,
        listMode: true,
        notes: [],
        selectMode: false,
        selected: [],
        isLoading: false,
        saveError: [],
        hasMadeInitialShowModal: false,
        categories: [],
        categoryId: 0,
        displayUnsavedChangesPopup: false
    };

    public componentDidMount() {
        this.fetchCategories();

        const { noteId } = this.props.match.params;

        if (!!noteId) {
            this.fetchAndDisplayNote(noteId)
        }
    }

    private fetchAndDisplayNote = noteId => {
        api.get(`/api/v1/notes/${noteId}`).then((response) => {
            this.setState({
                note: {
                    ...response.data, targets: response.data.targets.map((target) => {
                        return {
                            ...target,
                            key: target.id
                        }
                    })
                },
                showModal: true,
            });
        });
    }

    private fetchCategories = () => {
        if (!this.props.targetId) {
            return;
        }

        api.get(`/api/v1/notes/categories?targetId=${this.props.targetId}`).then((response) => {
            this.setState({ categories: response.data });
        }).catch(() => { });
    };

    private _getSelectionDetails(): string {
        const selectionCount = this._selection.getSelectedCount();

        this.setState({ selection: this._selection.getSelection() });

        switch (selectionCount) {
            case 0:
                return "No items selected";
            case 1:
                return "1 item selected: " + (this._selection.getSelection()[0] as IDetailsListBasicExampleItem).name;
            default:
                return `${selectionCount} items selected`;
        }
    }

    commandBarAddNote = () => {
        return {
            key: "add",
            text: localize("SkapaNyAnteckning"),
            onClick: () => {
                this.setState({ note: undefined }, () => {
                    this.setState({ showModal: true });
                });
            },
            iconProps: { iconName: "Add" },
            className: "commandBarAddButton",
            onRenderIcon: () => {
                return (
                    <Visage2Icon
                        icon={"add"}
                        size="big"
                        color={"white"} />
                )
            },
            theme: "primary"
        };
    };

    commandBarCategories = (): IContentHeaderButton => {
        return {
            key: "categories",
            text: `${localize("Kategorier")}${this.state.categoryId > 0
                ? ` - ${this.state.categories.find((category) => category.id === this.state.categoryId).name}`
                : ``
                }`,
            subMenuProps: {
                items: [
                    ...this.state.categories.map((category) => ({
                        key: category.id,
                        text: category.name,
                        onClick: () => {
                            this.setState(
                                {
                                    categoryId: category.id === this.state.categoryId ? 0 : category.id,
                                },
                                () => this.listRef.current.fetch()
                            );
                        },
                    })),
                    {
                        key: "all",
                        text: localize("VisaAlla"),
                        onClick: () => {
                            this.setState({ categoryId: 0 }, () => this.listRef.current.fetch());
                        },
                    },
                ],
            },
        };
    };

    fetchSearch = () => {
        this.listRef.current.fetch();
    };

    debouncedFetchSearch = debounce(() => this.fetchSearch(), 500);

    searchEvent(event: React.ChangeEvent, value: string): void {
        this.setState(
            {
                searchText: value,
            },
            this.debouncedFetchSearch
        );
    }

    renderBoxMode = (): JSX.Element => {
        const { notes } = this.state;
        return (
            <div>
                {notes.map((n) => {
                    return this.renderNoteBox(n);
                })}
            </div>
        );
    };

    toggleSelectNote = (id) => {
        let { selected } = this.state;
        if (selected.includes(id)) {
            selected = selected.filter((i) => i !== id);
        } else {
            selected.push(id);
        }
        this.setState({ selected });
    };

    print = (note) => {
        const elem = window.document.querySelector(`#note-${note.id}`);
        elem.classList.add("print-container");
        print(this.props.instanceName, note.title);
        elem.classList.remove("print-container")
    };

    pdf = (note) => {
        const elem = window.document.querySelector(`#note-${note.id}`);
        elem.classList.add("print-container");
        pdf(note.title);
        elem.classList.remove("print-container");
    };

    renderNoteBox = (note) => {
        const { selectMode } = this.state;

        return (
            <div
                className={classnames("note-box", { selectMode })}
                key={note.id}
                onClick={() => {
                    selectMode && this.toggleSelectNote(note.id);
                }}
            >
                <div className={classnames("box-content", { ["selected"]: this.state.selected.includes(note.id) })}>
                    <div className="title">
                        <span>{note.deleted ? <s>{note.title}</s> : note.title}</span>
                    </div>

                    <div className="actionMenu">
                        <ActionMenu
                            categories={[
                                {
                                    items: [
                                        ...(!note.deleted
                                            ? [
                                                {
                                                    text: localize("SkrivUt"),
                                                    onClick: () => {
                                                        this.print(note);
                                                    },
                                                },
                                                {
                                                    text: localize("HamtaSomPdf"),
                                                    onClick: () => {
                                                        this.pdf(note);
                                                    },
                                                },
                                            ]
                                            : []),
                                        {
                                            text: localize("Redigera"),
                                            onClick: () => {
                                                this.setState({ note: note }, () => {
                                                    this.setState({ showModal: true });
                                                });
                                            },
                                        },
                                        {
                                            text: note.deleted ? localize("Aterstall") : localize("TaBort"),
                                            onClick: () => {
                                                note.deleted
                                                    ? api
                                                        .put(`/api/v1/notes/${note.id}/restore`)
                                                        .then((_response) => {
                                                            this.listRef.current.fetch();
                                                        })
                                                    : api.delete(`/api/v1/notes/${note.id}`).then((_response) => {
                                                        this.listRef.current.fetch();
                                                    });
                                            },
                                        },
                                    ],
                                },
                            ]}
                        />
                    </div>
                    <div className="content">
                        <Label size="body-3">
                            <TinyFormattedContent content={note.content} />
                        </Label>
                    </div>
                    <div className="bottom">
                        <span className="notesIcon primaryFGColor primaryIconColor">
                            <Visage2Icon icon="edit" />
                        </span>
                        <UnstyledButton
                            className="primaryFGColor"
                            onClick={() => {
                                this.setState({ note: note }, () => {
                                    this.setState({ showModal: true });
                                });
                            }}
                        >
                        <div className="more-date">
                            {localize("VisaMer")} <br /> {this.formatDate(note.date)}
                        </div>
                        </UnstyledButton>
                    </div>
                    <div
                        className="hidden-print-content"
                        id={`note-${note.id}`}
                    >
                        <TinyFormattedContent content={note.content} />
                    </div>
                </div>
            </div>
        );
    };

    alternativeItems = () => {
        const { showDeleted, selectMode, isAscending } = this.state;

        return [
            {
                key: "hideDeleted",
                text: showDeleted ? localize("GomBorttagna") : localize("VisaBorttagna"),
                onClick: () => {
                    this.setState({ showDeleted: !showDeleted }, () => {
                        this.listRef.current.fetch();
                    });
                },
            },
        ]

    }

    formatDate(dateString) {
        const d = new Date(dateString);
        return d.toLocaleDateString();
    }

    renderUnsavedChangesDialog() {
        return (
            <CustomDialog
                message={localize("UnsavedChangesWarning")}
                show={!!this.state.displayUnsavedChangesPopup}
                confirmMessage={localize("Ok")}
                onDismiss={() => {
                    this.setState({
                        displayUnsavedChangesPopup: false
                    });
                }}
                onConfirm={() => {
                    this.setState({
                        displayUnsavedChangesPopup: false
                    }, () => {
                        this.setState({ showModal: false });
                    });
                }} />
        )
    }

    renderListTypeSwitch = () => {
        return(
            <div className="list-type-switch">
            <UnstyledButton
                className={classNames("switch-button", {
                    active: this.state.listMode,
                })}
                onClick={() => { this.setState({ listMode: true }); }}
            >
                <Visage2Icon
                    color={this.state.listMode ? "visageMidBlue" : "primaryContent"}
                    icon="menu-1"
                    size="small" />
            </UnstyledButton>
            <UnstyledButton
                className={classNames("switch-button", {
                    active: !this.state.listMode,
                })}
                onClick={() => { this.setState({ listMode: false }); }}
            >
                <Visage2Icon
                    color={!this.state.listMode ? "visageMidBlue" : "primaryContent"}
                    icon="element-3"
                    size="small" />
            </UnstyledButton>
        </div>
        );
    }

    render() {
        const sanitizer = dompurify.sanitize;
        const { showModal, isLoading } = this.state;

        if (isLoading) return <Loader />;

        return (
            <div className="notes-container">
                <Helmet>
                    <title>{localize("appNotes")}</title>
                </Helmet>
                {!this.props.targetId && (
                    <Breadcrumbs
                        displayInstance
                        items={[
                            {
                                key: "0",
                                text: localize("appNotes"),
                                link: "/notes",
                            }
                        ]}
                    />
                )}
                {showModal && (
                    <Modal
                        className="spintr-modal modalWithPopupHeader"
                        isOpen={true}
                        isClickableOutsideFocusTrap={true}
                        forceFocusInsideTrap={false}
                        onDismiss={() => {
                            if (isAnythingDirty()) {
                                this.setState({
                                    displayUnsavedChangesPopup: true
                                });
                            } else {
                                this.setState({
                                    showModal: false
                                });
                            }
                        }}
                    >
                        <PopupHeader
                            text={!!this.state.note && !!this.state.note.id ? localize("Anteckning") : localize("NyAnteckning")}
                            onClose={() => {
                                this.setState({ showModal: false }, () => {
                                    this.listRef.current.fetch();
                                    this.fetchCategories();
                                });
                                this.props.history.push(this.props.targetId ? `/groups/${this.props.targetId}/notes` : `/notes`,)
                            }} />
                        <NotesEditView
                            note={this.state.note}
                            targetId={this.props.targetId}
                            onCancel={() => {
                                this.setState({ showModal: false }, () => {
                                    this.listRef.current.fetch();
                                    this.fetchCategories();
                                });
                            }}
                            isInModal
                        />
                    </Modal>
                )}
                <div>
                    <PageHeader
                        title={localize("appNotes")}
                        subText={localize("NOTES_SUBTITLE")}
                        buttons={[
                            {
                                key: "options",
                                text: localize("Alternativ"),
                                subMenuProps: {
                                    items: [{
                                        key: "hideDeleted",
                                        text: this.state.showDeleted ? localize("GomBorttagna") : localize("VisaBorttagna"),
                                        onClick: () => {
                                            this.setState({ showDeleted: !this.state.showDeleted }, () => {
                                                this.listRef.current.fetch();
                                            });
                                        },
                                    }]
                                }
                            },
                            {key: "listSwitch",
                                onRender: this.props.viewMode < SpintrTypes.ViewMode.TabletPortrait
                                    ? undefined
                                    : this.renderListTypeSwitch,
                                    text: localize("LIST_DISPLAY_VIEW_AS"),
                                    subMenuProps: {
                                        items: [
                                            {
                                                key: "list",
                                                text: localize("LIST_DISPLAY_LIST"),
                                                onClick: () => this.setState({ listMode: true }),
                                            },
                                            {
                                                key: "cards",
                                                text: localize("LIST_DISPLAY_CARDS"),
                                                onClick: () => this.setState({ listMode: false }),
                                            },
                                        ],
                                    },
                            },
                            this.commandBarAddNote(),
                            ...(this.props.targetId ? [this.commandBarCategories()] : []),
                            ...(this.props.isSmallViewMode ? this.alternativeItems() : []),
                        ]}
                        displaySearch
                        onSearchQueryChange={(value: string) => {
                            this.searchEvent(null, value);
                        }}
                    />
                </div>
                <div className="list topList" style={{ display: this.state.listMode ? "block" : "none" }}>
                    <SpintrList
                        disableCommandBar
                        disablePagination
                        selection={this._selection}
                        ref={this.listRef}
                        fetch={(skip, take, columnId, isAscending, searchQuery) => {
                            return new Promise((resolve, reject) => {
                                api
                                    .get(`/api/v1/notes`, {
                                        params: {
                                            showDeleted: this.state.showDeleted,
                                            isAscending: isAscending,
                                            orderByColumn: columnId,
                                            searchText: this.state.searchText,
                                            targetId: this.props.targetId,
                                            categoryId: this.state.categoryId,
                                        },
                                    })
                                    .then((response: AxiosResponse) => {
                                        this.setState(
                                            {
                                                notes: response.data.notes,
                                            },
                                            () => {
                                                if (
                                                    this.props.location.pathname.split("/").length === 5 &&
                                                    !this.state.hasMadeInitialShowModal
                                                ) {
                                                    const noteId = this.props.location.pathname.split("/").pop();
                                                    const item = this.state.notes.find(
                                                        (n) => n.id === parseInt(noteId)
                                                    );

                                                    if (item) {
                                                        this.setState({ note: item }, () => {
                                                            this.setState({
                                                                showModal: true,
                                                                hasMadeInitialShowModal: true,
                                                            });
                                                        });
                                                    }
                                                }
                                            }
                                        );

                                        resolve({
                                            data: response.data.notes,
                                            totalCount: response.data.totalCount,
                                        });
                                    }).catch(() => { });
                            });
                        }}
                        orderByColumn={"date"}
                        columns={[
                            {
                                key: 0,
                                name: localize("Namn"),
                                fieldName: "title",
                                maxWidth: 250,
                                onRender: (item) => {
                                    return (
                                        <span>
                                            <b>
                                                <UnstyledButton
                                                    className="primaryBorderColorHover"
                                                    onClick={() => {
                                                        this.setState({ note: item }, () => {
                                                            this.setState({ showModal: true });
                                                        });
                                                    }}
                                                >
                                                    {item.deleted ? <s>{item.title}</s> : item.title}
                                                </UnstyledButton>
                                            </b>
                                        </span>
                                    );
                                },
                            },
                            {
                                key: 1,
                                name: localize("Innehall"),
                                fieldName: "content",
                                onRender: (item) => {
                                    const content = truncate(
                                        sanitizer(item.content.replace(/&nbsp;/g, ""), { ALLOWED_TAGS: [] }),
                                        250
                                    );

                                    if (item.deleted) {
                                        return (
                                            <span>
                                                <s>{content}</s>
                                            </span>
                                        );
                                    } else {
                                        return (
                                            <>
                                                <span>{content}</span>
                                                <div
                                                    className="hidden-print-content"
                                                    id={`note-${item.id}`}
                                                >
                                                    <TinyFormattedContent content={item.content} />
                                                </div>
                                            </>
                                        );
                                    }
                                },
                            },
                            {
                                key: 2,
                                name: localize("Datum"),
                                fieldName: "date",
                                onRender: (item) => {
                                    return <span>{this.formatDate(item.date)}</span>;
                                },
                            },

                            {
                                name: "",
                                key: "actionmenu",
                                minWidth: 40,
                                onRender: (item: any) => {
                                    return (
                                        <ActionMenu
                                            categories={[
                                                {
                                                    items: [
                                                        ...(!item.deleted
                                                            ? [
                                                                {
                                                                    text: localize("SkrivUt"),
                                                                    onClick: () => {
                                                                        this.print(item);
                                                                    }
                                                                },
                                                                {
                                                                    text: localize("HamtaSomPdf"),
                                                                    onClick: () => {
                                                                        this.pdf(item);
                                                                    },
                                                                },
                                                            ]
                                                            : []),
                                                        {
                                                            text: localize("Redigera"),
                                                            onClick: () => {
                                                                this.setState({ note: item }, () => {
                                                                    this.setState({ showModal: true });
                                                                });
                                                            },
                                                        },
                                                        {
                                                            text: item.deleted
                                                                ? localize("Aterstall")
                                                                : localize("TaBort"),
                                                            onClick: () => {
                                                                item.deleted
                                                                    ? api
                                                                        .put(`/api/v1/notes/${item.id}/restore`)
                                                                        .then((_response) => {
                                                                            this.listRef.current.fetch();
                                                                        })
                                                                    : api
                                                                        .delete(`/api/v1/notes/${item.id}`)
                                                                        .then((_response) => {
                                                                            this.listRef.current.fetch();
                                                                        });
                                                            },
                                                        },
                                                    ],
                                                },
                                            ]}
                                        />
                                    );
                                },
                            },
                        ]}
                    />
                </div>
                {!this.state.listMode && this.renderBoxMode()}
                {
                    this.renderUnsavedChangesDialog()
                }
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        ...props,

        isSmallViewMode: state.ui.isSmallViewMode,
        instanceName: state.instance.get("name"),
        viewMode: state.ui.viewMode,
    };
};

// @ts-ignore
export default withRouter(connect(mapStateToProps)(NotesView));
