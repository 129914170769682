import { AxiosResponse } from "axios";
import api from "src/spintr/SpintrApi";

import {
    FETCH_CALENDAR_SIDEBAR,
    IFetchCalendarSidebarParams,
    ISetCalendarPopupAction,
    SET_CALENDAR_POPUP,
    FETCH_CALENDAR,
    IFetchCalendarParams,
    ISetCalendarPopupParams,
    CREATE_CALENDAR_EVENT,
    SET_ACTIVE_CALENDAR,
    CLEAR_ERROR_LIST,
    UPDATE_CALENDAR_EVENT,
    SET_CALENDAR_COLOR,
    TOGGLE_CALENDAR_EVENT_DELETED,
    FETCH_CALENDAR_EVENT,
    UPDATE_CALENDAR_EVENT_REALTIME,
    JOIN_CALENDAR_EVENT,
    LEAVE_CALENDAR_EVENT,
    FETCH_INPAGE_CALENDAR,
    IFetchInPageCalendarParams
} from "./action-types";

const eventsBaseUrl = "/api/v1/calendarevent";

export const fetchCalendarSidebar = (params: IFetchCalendarSidebarParams) => ({
    meta: { params },
    payload: api.get<Spintr.ICalendarEvent[]>(eventsBaseUrl, { params }),
    type: FETCH_CALENDAR_SIDEBAR,
});

export const fetchInPageCalendar = (params: IFetchInPageCalendarParams) => ({
    meta: { params },
    payload: api.get<Spintr.ICalendarEvent[]>(eventsBaseUrl, { params }),
    type: FETCH_INPAGE_CALENDAR,
})

export const fetchCalendarEvent = (params) => ({
    meta: { params },
    payload: api.get<Spintr.ICalendarEvent>(`${eventsBaseUrl}/${params.id}`),
    type: FETCH_CALENDAR_EVENT,
});

export const setCalendarPopup = (params: ISetCalendarPopupParams): ISetCalendarPopupAction => ({
    eventId: params.eventId,
    isOpen: params.isOpen,
    startDate: params.startDate,
    endDate: params.endDate,
    editMode: params.editMode,
    event: params.event,
    preSelectedCalendarId: params.preSelectedCalendarId,
    hideCalendarSelection: params.hideCalendarSelector,
    onSave: params.onSave,
    type: SET_CALENDAR_POPUP,
    externalId: params.externalId
});

export const fetchCalendar = (params: IFetchCalendarParams) => ({
    type: FETCH_CALENDAR,
    payload: api.get<Spintr.ICalendarEvent[]>(`${eventsBaseUrl}/fullcalendar`, { params }),
});

export const createCalendarEvent = (event: any) => ({
    type: CREATE_CALENDAR_EVENT,
    payload: api.post("/api/v1/calendarevent", event),
});

export const updateCalendarEvent = (event: any) => ({
    type: UPDATE_CALENDAR_EVENT,
    payload: api.put(`/api/v1/calendarevent/${event.id}`, event),
});

export const updateCalendarEventRealtime = (event: any) => ({
    meta: { event },
    type: UPDATE_CALENDAR_EVENT_REALTIME,
});

export const toggleCalendarEventDeleted = (event: any) => {
    var payload;
    if (event.exchangeId) {
        payload = api.put("/api/v1/calendarevent/deleteexchangeevent", JSON.stringify(event.exchangeId), { headers: { "Content-Type": "application/json" } });
    } else if (event.googleId) {
        payload = api.put("/api/v1/calendarevent/deletegoogleevent", JSON.stringify(event.exchangeId), { headers: { "Content-Type": "application/json" } });
    } else {
        payload = api.put(`/api/v1/calendarevent/${event.id}/toggledelete`);
    }

    return {
        type: TOGGLE_CALENDAR_EVENT_DELETED,
        meta: { event },
        payload,
    };
};

export const setActiveCalendar = (id: number) => ({
    id,
    type: SET_ACTIVE_CALENDAR,
});

export const setCalendarColor = (id: number, name: string, color: string) => ({
    payload: api.post(`/api/v1/calendar/${id}`, {
        id,
        name,
        color,
    }),
    meta: {
        id,
        color,
    },
    type: SET_CALENDAR_COLOR,
});

export const clearErrorList = () => ({
    type: CLEAR_ERROR_LIST,
});

export const joinEvent = (id: number, userId: number, response: AxiosResponse) => ({
    type: JOIN_CALENDAR_EVENT,
    meta: {
        id,
        userId,
        response
    },
});

export const leaveEvent = (id: number, userId: number, response: AxiosResponse) => ({
    type: LEAVE_CALENDAR_EVENT,
    meta: {
        id,
        userId,
        response
    },
});

export const CalendarActions = {
    fetchCalendarSidebar,
};
export default CalendarActions;
