import axios, { AxiosResponse, CancelTokenSource } from "axios";
import {
    DefaultButton, Icon,
    Image,
    ImageFit, Label, PrimaryButton,
    Text
} from "@fluentui/react";
import React, { Component, ReactNode } from "react";
import { Helmet } from 'react-helmet';
import { connect, DispatchProp } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Link } from "react-router-dom";
import { from, of, Subscription } from "rxjs";
import { catchError } from "rxjs/operators";
import { localize, localizeFormat } from "src/l10n";
import { searchFiles } from "src/office365";
import {
    ClearResultsAction,
    clearSearchResults,
    executeSearchAction,
    ExecuteSearchAction
} from "src/search/actions";
import { ISearchParams, submitFeedback } from "src/search/api";
import { SearchResultObjectHit } from "src/search/components";
import { ISearchFeedback, SearchFileType, SearchSorting, SearchTimeSpan } from "src/search/types";
import { ISearchHeaders } from "src/search/utils";
import { SpintrTypes } from "src/typings";
import { ContentWithInfoPanel, ContentWithSubmenu, Loader, PageHeader, setDisableWrapperBackground, setDisplayHeaderSearch, SetDisplayHeaderSearch, setOverlayId, SpintrUser, Submenu, UnstyledButton } from "src/ui";
import { capitalizeFirstLetter, getQueryStringMap, NoOp } from "src/utils";
import "./SearchResultView.scss";
import api from "src/spintr/SpintrApi";
import ExpandableBox from "src/ui/components/ExpandableBox/ExpandableBox";

const getCategory = (name: string, nodes: SubmenuItem[], isExpanded?: boolean): SubmenuItem => ({
    action: NoOp,
    isExpanded,
    name,
    nodes,
    url: "",
});

const getItem = (name: string, action: () => void, active: boolean = false, count: number = undefined): SubmenuItem => ({
    action,
    active,
    count,
    name,
    url: "",
});

const objectTypes: SpintrTypes.UberType[] = [
    SpintrTypes.UberType.Status,
    SpintrTypes.UberType.Bookmark,
    SpintrTypes.UberType.Comment,
    SpintrTypes.UberType.File,
    SpintrTypes.UberType.TextPage,
    SpintrTypes.UberType.NewsArticle,
    SpintrTypes.UberType.Event,
    SpintrTypes.UberType.Gallery,
    SpintrTypes.UberType.Wiki,
    SpintrTypes.UberType.WikiArticle,
    SpintrTypes.UberType.Blog,
    SpintrTypes.UberType.BlogPost,
    SpintrTypes.UberType.Group,
    SpintrTypes.UberType.CaneaFile,
    SpintrTypes.UberType.QA,
    SpintrTypes.UberType.User,
    SpintrTypes.UberType.AcademyCourse,
];

const appTypeMap = {
    [SpintrTypes.SpintrApp.Media]: [
        SpintrTypes.UberType.Gallery,
        SpintrTypes.UberType.Image,
        SpintrTypes.UberType.ImageTag,
        SpintrTypes.UberType.ImagePost,
    ],

    [SpintrTypes.SpintrApp.Blogs]: [
        SpintrTypes.UberType.Blog,
        SpintrTypes.UberType.BlogPost,
    ],

    [SpintrTypes.SpintrApp.News]: [
        SpintrTypes.UberType.NewsArticle,
    ],

    [SpintrTypes.SpintrApp.Calendars]: [
        SpintrTypes.UberType.Event,
    ],

    [SpintrTypes.SpintrApp.Files]: [
        SpintrTypes.UberType.File,
    ],

    [SpintrTypes.SpintrApp.Wikis]: [
        SpintrTypes.UberType.Wiki,
        SpintrTypes.UberType.WikiArticle,
        SpintrTypes.UberType.WikiDiscussion,
        SpintrTypes.UberType.WikiSection,
        SpintrTypes.UberType.WikiSectionBackup,
    ],

    [SpintrTypes.SpintrApp.Suppliers]: [
        SpintrTypes.UberType.Supplier,
    ]
}

const fileTypeMap = {
    [SearchFileType.Audio]: [
        SpintrTypes.FileType.Mp3,
        SpintrTypes.FileType.Wav,
    ],
    
    [SearchFileType.Images]: [
        SpintrTypes.FileType.Jpg,
        SpintrTypes.FileType.Png,
        SpintrTypes.FileType.Gif,
    ],
    
    [SearchFileType.Sheets]: [
        SpintrTypes.FileType.Xls,
        SpintrTypes.FileType.Xlsx,
        SpintrTypes.FileType.Csv,
        SpintrTypes.FileType.Ods,
        SpintrTypes.FileType.Sxc,
    ],
    
    [SearchFileType.Documents]: [
        SpintrTypes.FileType.Pdf,
        SpintrTypes.FileType.Doc,
        SpintrTypes.FileType.Docx,
        SpintrTypes.FileType.Rtf,
        SpintrTypes.FileType.Html,
        SpintrTypes.FileType.Txt,
        SpintrTypes.FileType.Odt,
        SpintrTypes.FileType.Sxw,
    ],
    
    [SearchFileType.Presentations]: [
        SpintrTypes.FileType.Ppt,
        SpintrTypes.FileType.Pptx,
        SpintrTypes.FileType.Pps,
        SpintrTypes.FileType.Odp,
        SpintrTypes.FileType.Sxi,
    ],
    
    [SearchFileType.Archives]: [
        SpintrTypes.FileType.Zip,
    ],
}

/**
 * Properties derived from the application state
 */
interface IStateProps {
    enabledApps: Spintr.ISystemApp[],
    errorMessageTag?: string;
    headers?: ISearchHeaders;
    isLoading: boolean;
    lastParams?: ISearchParams,
    office365Enabled: boolean;
    enableSharepoint: boolean;
    enableOnedrive: boolean;
    results?: Spintr.ISearchResult[];
}

interface IDispatchProps {
    clearSearchResults: ClearResultsAction;
    executeSearchAction: ExecuteSearchAction;
    setDisplayHeaderSearch?: SetDisplayHeaderSearch;
    setDisableWrapperBackground?: any;
    setOverlayId?: any;
}

type Props = IStateProps & IDispatchProps & DispatchProp & RouteComponentProps;

interface IState {
    departments: Spintr.IDepartment[];
    isLoadingDepartments: boolean;

    oneDriveResults?: Spintr.IOffice365FileSearchEnvelope;
    sharepointResults?: Spintr.IOffice365FileSearchEnvelope;

    showOffice365: boolean;
    isLoadingOffice365: boolean;

    application?: SpintrTypes.SpintrApp;
    fileType?: SearchFileType,
    departmentId?: number;
    objectType: SpintrTypes.UberType;
    sorting: SearchSorting;
    timeSpan: SearchTimeSpan;
}

// TODO: Still need to add: Office365 items

/**
 * A view that presents results from a search query and lets users filter the search
 */
class SearchResultView extends Component<Props, IState> {
    protected submenuRef: any; // how do you type connected components?
    protected departmentsSubscription: Subscription | null;
    protected office365Subscription: Subscription | null;
    private isUnmounted: boolean = false;
    protected cancelTokenSource: CancelTokenSource;

    constructor(props: Props) {
        super(props);


        const qs = getQueryStringMap(this.props.location);
        this.state = {
            departments: [],
            isLoadingDepartments: true,

            isLoadingOffice365: false,
            showOffice365: qs["s"] === "365",

            objectType: SpintrTypes.UberType.Unknown,
            sorting: SearchSorting.Relevance,
            timeSpan: SearchTimeSpan.AllTime,
        }

        this.office365Subscription = null;

        this.submenuRef = React.createRef();

        this.fetchFilters = this.fetchFilters.bind(this);
        this.onDepartmentsLoaded = this.onDepartmentsLoaded.bind(this);
        this.onLoadMoreClick = this.onLoadMoreClick.bind(this);
        this.onOffice365Fetched = this.onOffice365Fetched.bind(this);
        this.onResultClick = this.onResultClick.bind(this);
        this.onToggleOffice365 = this.onToggleOffice365.bind(this);
        this.renderItem = this.renderItem.bind(this);
        this.renderOffice365Item = this.renderOffice365Item.bind(this);
        this.renderUser = this.renderUser.bind(this);
    }

    public componentDidMount(): void {
        this.props.setDisableWrapperBackground(true);
        this.props.setDisplayHeaderSearch(false);
        this.props.setOverlayId("");

        // TODO: Add error handling here
        this.departmentsSubscription =
            from(api.get<Spintr.IDepartment[]>("/api/v1/departments?take=0"))
                .pipe(
                    catchError((err) => of(null)),
                )
                .subscribe(this.onDepartmentsLoaded);

        if (!this.props.results) {
            // Do nothing here, searches on page load are handled by
            // the SpintrHeaderSearch component
            return;
        }

        if (!this.props.lastParams) {
            return;
        }

        this.executeOffice365Search();
    }

    public componentWillUnmount(): void {
        this.props.setDisableWrapperBackground(false);
        this.isUnmounted = true;

        // Tidy up search params so they don't interfere with next search
        this.props.clearSearchResults();

        if (this.departmentsSubscription) {
            this.departmentsSubscription.unsubscribe();
        }

        if (this.office365Subscription) {
            this.office365Subscription.unsubscribe();
        }
    }

    public componentDidUpdate(prevProps: Props) {
        const prevQuery: string = (prevProps.lastParams || {}).query;
        const nextQuery: string = (this.props.lastParams || {}).query;

        if (prevQuery !== nextQuery) {
            this.executeOffice365Search();
        }

        if (prevProps.headers === this.props.headers) {
            return;
        }

        if (!this.submenuRef.current) {
            return;
        }

        // Force re-fetch
        this.submenuRef.current.fetch(true);
    }

    public render(): ReactNode {
        if (this.state.isLoadingDepartments) {
            return <Loader />;
        }

        let totalCount = this.getGeneralResultsTotal();
        if (this.props.headers) {   
            if (this.state.objectType) {
                const objectHits = this.props.headers.objectTypes[
                    this.state.objectType
                ] || 0;

                totalCount -= (totalCount - objectHits);
            }
        }

        let office365HasMore: boolean = false;
        let office365Count: number = 0;
        if (this.state.oneDriveResults) {
            office365Count += this.state.oneDriveResults.items.length;
            office365HasMore = office365HasMore || this.state.oneDriveResults.hasMore;
        }
        if (this.state.sharepointResults) {
            office365Count += this.state.sharepointResults.items.length;
            office365HasMore = office365HasMore || this.state.sharepointResults.hasMore;
        }

        const switchButtonText = this.state.showOffice365
            ? localize("SEARCH_SHOW_INTERNAL_HITS")
            : localizeFormat(
                "SEARCH_SHOW_X_O365_RESULTS_F",
                office365Count + "" + (office365HasMore ? "+" : "")
            );

        const searchResultCount = this.state.showOffice365 ?
            (office365Count + "" + (office365HasMore ? "+" : "")) :
            totalCount;

        const searchResultCountString = searchResultCount +
            " " +
            (((this.state.showOffice365 && office365HasMore) || searchResultCount > 1) ? localize("SEARCH_RESULTS") : localize("SEARCH_RESULT")).toLocaleLowerCase() +
            " " +
            localize("For").toLocaleLowerCase() +
            ' "' +
            this.props.lastParams?.query +
            '"';

        return (
            <div id="SearchResultView">
                <Helmet>
                    <title>{localize("Sok")}</title>
                </Helmet>
                <div className="heading">
                    <div className="query-info">
                        <PageHeader title= {searchResultCountString} />
                    </div>
                    {this.props.office365Enabled && office365Count > 0 && (
                        <div className="office365-switch">
                            <DefaultButton
                                iconProps={this.state.showOffice365
                                    ? null
                                    : { iconName: "OfficeLogo" }
                                }
                                onClick={this.onToggleOffice365}
                                text={switchButtonText}
                            />
                        </div>
                    )}
                </div>
                <ContentWithInfoPanel
                    template={1}
                    renderInfoPanel = {() => {
                    const filters = this.fetchFilters({}, []);
                        return (
                            <div>
                                {filters.map((filter: any, index: number) => {
                                    return (
                                        <ExpandableBox isExpandable={true} isExpandedByDefault={filter.isExpanded} title={filter.name}>
                                            {filter.nodes.map((nodeFilter: any, nodeIndex: number) => {
                                                return (
                                                    <UnstyledButton onClick={nodeFilter.action}>
                                                        <Label className={nodeFilter.active ? "activeIsBold" : ""}>
                                                            {nodeFilter.name}
                                                        </Label>
                                                    </UnstyledButton>
                                                );
                                            })}
                                        </ExpandableBox>
                                    );
                                })}
                            </div>
                        )
                    }}
                >
                    <div className="results-view">
                        {!!this.props.results && (this.props.results.length > 0 || this.state.sharepointResults?.items.length > 0 || this.state.oneDriveResults?.items.length > 0) && (
                            <div className="general-results">
                                
                                {this.state.showOffice365 && (
                                    <>
                                    {
                                         (this.state.sharepointResults?.items || []).concat(this.state.oneDriveResults?.items || []).map((item: any, index: number) => {
                                            return (
                                                <div key={item.id}>
                                                    {
                                                        this.renderOffice365Item(item, index)
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                    {(this.state.showOffice365 && this.state.isLoadingOffice365) && (
                                        <Loader />
                                    )}
                                    </>
                                )}
                                {!this.state.showOffice365 && (
                                    <>
                                        {
                                             this.props.results.map((item: any, index: number) => {
                                                return (
                                                    <div key={item.id}>
                                                        {
                                                            this.renderItem(item)
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                        {totalCount > this.props.results.length && !this.props.isLoading && (
                                            <div className="load-more">
                                                <PrimaryButton
                                                    onClick={this.onLoadMoreClick}
                                                    text={localize("VisaFler")}
                                                />
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        )}
                        {!this.props.isLoading &&
                            ((this.props.results?.length === 0 && !this.state.showOffice365) ||
                                (this.state.sharepointResults?.items.length === 0 &&
                                    this.state.oneDriveResults?.items.length === 0 &&
                                    this.state.showOffice365)) && (
                                <Text variant="mediumPlus">{localize("SEARCH_NO_RESULTS")}</Text>
                        )}
                    </div>
                    {this.props.isLoading && (
                        <Loader />
                    )}
                </ContentWithInfoPanel>
            </div>
        )
    }

    protected executeOffice365Search(): void {
        if (!this.props.office365Enabled || (!this.props.enableSharepoint && !this.props.enableOnedrive)) {
            return;
        }

        if (!this.props.lastParams || !this.props.lastParams.query) {
            return;
        }

        if (this.office365Subscription) {
            this.office365Subscription.unsubscribe();
            this.office365Subscription = null;
        }

        const promises: Array<Promise<Spintr.IOffice365FileSearchEnvelope>> = [];

        if (this.props.enableOnedrive) {
            promises.push(searchFiles({
                ignoreEmptyQuery: true,
                orderByLatest: false,
                query: this.props.lastParams?.query,
                serviceType: 1,
                skip: this.state.oneDriveResults?.items.length || 0,
                take: 100,
            }));
        }

        if (this.props.enableSharepoint) {
            promises.push(searchFiles({
                ignoreEmptyQuery: true,
                orderByLatest: false,
                query: this.props.lastParams!.query,
                serviceType: 2,
                skip: this.state.sharepointResults?.items.length || 0,
                take: 100,
            }));
        }

        this.setState({ isLoadingOffice365: true}, () => {
            from(Promise.all(promises))
                .pipe(
                    catchError((err) => of(null)),
                )
                .subscribe(this.onOffice365Fetched);
        });
    }

    protected executeSearch(skip?: number): void {
        if (!this.props.executeSearchAction) {
            return;
        }

        if (!this.props.lastParams) {
            return;
        }

        let afterDate: Date | undefined;
        if (this.state.timeSpan !== SearchTimeSpan.AllTime) {
            const now = new Date();

            switch (this.state.timeSpan) {
                case SearchTimeSpan.Today:
                    afterDate = new Date(
                        now.getFullYear(),
                        now.getMonth(),
                        now.getDate(),
                        0, 0, 0);
                    break;

                case SearchTimeSpan.LastWeek:
                    afterDate = new Date(
                        now.getTime() - (7 * 24 * 60 * 60 * 1000)
                    );
                    break;

                case SearchTimeSpan.LastMonth:
                    afterDate = new Date(
                        now.getTime() - (31 * 24 * 60 * 60 * 1000)
                    );
                    break;

                case SearchTimeSpan.LastYear:
                    afterDate = new Date(
                        now.getTime() - (365 * 24 * 60 * 60 * 1000)
                    );
                    break;
            }
        }

        if (typeof this.cancelTokenSource != typeof undefined) {
            this.cancelTokenSource.cancel("Operation canceled due to new request.")
        }

        this.cancelTokenSource = axios.CancelToken.source()

        this.props.executeSearchAction({
            ...this.props.lastParams,

            skip,

            after: afterDate,
            departmentId: this.state.departmentId,
            excludedTypes: !this.state.application
                ? undefined
                : objectTypes.filter(
                    (t) => appTypeMap[this.state.application].indexOf(t) === -1
                    ),
            fileTypes: !!this.state.fileType
                ? fileTypeMap[this.state.fileType]?.length === 0
                    ? undefined
                    : fileTypeMap[this.state.fileType]
                : undefined,
            objectType: this.state.objectType,
            sort: this.state.sorting,
            take: 6,
        }, this.cancelTokenSource.token)
    }

    protected fetchFilters(params, prevItems) {
        const { headers } = this.props;
        const hasHeaders = !!headers;

        const totalCount = this.getGeneralResultsTotal();
        const allText = localize("Alla");

        const apps: SubmenuItem[] = hasHeaders
            ? Object.keys(appTypeMap)
                .filter((key) => !isNaN(+key))
                .map((key) => parseInt(key, 10))
                .filter((key) => this.props.enabledApps.some(
                    (app) => app.id === key
                ))
                .map((key) => {
                    const types: Spintr.UberType[] = appTypeMap[key];
                    const typeStats = headers.objectTypes;

                    const count = types.reduce(
                        (acc, val) => acc + (typeStats[val] || 0),
                        0,
                    );

                    if (!count) {
                        return undefined;
                    }

                    return getItem(
                        localize("app" + SpintrTypes.SpintrApp[key]),
                        this.updateApp.bind(this, key),
                        this.state.application === key,
                        count,
                    );
                })
                .filter((item) => !!item)
                .sort((a, b) => a.name > b.name ? 1 : -1)
            : [];

        const items: SubmenuItem[] = [
            getCategory(localize("Sortering"), [
                getItem(
                    localize("relevans"),
                    this.updateSorting.bind(this, SearchSorting.Relevance),
                    this.state.sorting === SearchSorting.Relevance,
                ),
                getItem(
                    localize("aldst"),
                    this.updateSorting.bind(this, SearchSorting.Earliest),
                    this.state.sorting === SearchSorting.Earliest,
                ),
                getItem(
                    localize("nyast"),
                    this.updateSorting.bind(this, SearchSorting.Latest),
                    this.state.sorting === SearchSorting.Latest,
                ),
            ],
            prevItems.find(cat => cat.name === localize("Sortering"))?.isExpanded ?? true),
            getCategory(localize("Innehallstyp"), [
                    getItem(
                        allText,
                        this.updateObjectType.bind(this, SpintrTypes.UberType.Unknown),
                        this.state.objectType === SpintrTypes.UberType.Unknown,
                        totalCount,
                    )
                ].concat(objectTypes
                    .map((type) => getItem(
                        capitalizeFirstLetter(
                            localize(`Ubertype${type}_1_0`)
                        ),
                        this.updateObjectType.bind(this, type),
                        this.state.objectType === type,
                        hasHeaders ? headers.objectTypes[type] : undefined
                    ))
                    .filter((item) => !hasHeaders || !!item.count)
                    .sort((a, b) => a.name > b.name ? 1 : -1)
                ),
                prevItems.find(cat => cat.name === localize("Innehallstyp"))?.isExpanded ?? true,
            ),
            getCategory(localize("Tid"), [
                getItem(
                    allText,
                    this.updateTimeSpan.bind(this, SearchTimeSpan.AllTime),
                    this.state.timeSpan === SearchTimeSpan.AllTime,
                    totalCount,
                ),
                getItem(
                    localize("Idag"),
                    this.updateTimeSpan.bind(this, SearchTimeSpan.Today),
                    this.state.timeSpan === SearchTimeSpan.Today,
                    hasHeaders 
                        ? headers.timePeriods[SearchTimeSpan.Today]
                        : 0,
                ),
                getItem(
                    localize("SenasteVeckan"),
                    this.updateTimeSpan.bind(this, SearchTimeSpan.LastWeek),
                    this.state.timeSpan === SearchTimeSpan.LastWeek,
                    hasHeaders 
                        ? headers.timePeriods[SearchTimeSpan.LastWeek]
                        : 0,
                ),
                getItem(
                    localize("SenasteManaden"),
                    this.updateTimeSpan.bind(this, SearchTimeSpan.LastMonth),
                    this.state.timeSpan === SearchTimeSpan.LastMonth,
                    hasHeaders 
                        ? headers.timePeriods[SearchTimeSpan.LastMonth]
                        : 0,
                ),
                getItem(
                    localize("SenasteAret"),
                    this.updateTimeSpan.bind(this, SearchTimeSpan.LastYear),
                    this.state.timeSpan === SearchTimeSpan.LastYear,
                    hasHeaders 
                        ? headers.timePeriods[SearchTimeSpan.LastYear]
                        : 0,
                )
            ],
            prevItems.find(cat => cat.name === localize("Tid"))?.isExpanded ?? false),
        ];

        const filesAppInstalled = this.props.enabledApps.some(
            (app) => app.id === SpintrTypes.SpintrApp.Files,
        );

        if (filesAppInstalled && headers) {
            const fileTypes = Object.keys(fileTypeMap)
                .filter((key) => !isNaN(+key))
                .map((key) => parseInt(key, 10) as SearchFileType)
                .map((key) => {
                    const types: Spintr.FileType[] = fileTypeMap[key];
                    const typeStats = headers.fileTypes;

                    const count = types.reduce(
                        (acc, val) => acc + (typeStats[val] || 0),
                        0,
                    );

                    if (!count) {
                        return undefined;
                    }

                    let name: string;
                    switch (key) {
                        case SearchFileType.Audio:
                            name = "Ljudfiler";
                            break;

                        case SearchFileType.Images:
                            name = "Images";
                            break;

                        case SearchFileType.Sheets:
                            name = "Kalkylblad_plural";
                            break;

                        case SearchFileType.Documents:
                            name = "Dokument_plural";
                            break;

                        case SearchFileType.Presentations:
                            name = "Presentationer";
                            break;

                        case SearchFileType.Archives:
                            name = "Arkiv";
                            break;
                    }

                    return getItem(
                        localize(name),
                        this.updateFileType.bind(this, key),
                        this.state.fileType === key,
                        count
                    );
                })
                .filter((item) => !!item)
                .sort((a, b) => a.name > b.name ? 1 : -1);

            if (fileTypes.length > 0) {
                items.push(
                    getCategory(
                        localize("Filtyp"), 
                        [getItem(
                            allText,
                            this.updateFileType.bind(this),
                            !this.state.fileType,
                            totalCount,
                        )].concat(fileTypes),
                        prevItems.find(cat => cat.name === localize("Filtyp"))?.isExpanded ?? false,
                    ),
                );
            }
        }

        if (apps.length > 0) {
            items.push(
                getCategory(
                    localize("Applikationer"), 
                    apps.length === 0
                        ? []
                        : [
                            getItem(
                                allText,
                                this.updateApp.bind(this, undefined),
                                this.state.application === undefined,
                                totalCount,
                            )
                        ].concat(apps),
                    prevItems.find(cat => cat.name === localize("Applikationer"))?.isExpanded ?? false,
                ),
            );
        }

        if (this.state.departments.length > 0) {
            const departments: SubmenuItem[] = hasHeaders
                ? Object.keys(headers.departments)
                    .filter((key) => !isNaN(+key))
                    .map((key) => parseInt(key, 10))
                    .map((departmentId) => {
                        const department = this.state.departments.find(
                            (d) => d.id === departmentId
                        );

                        if (!department) {
                            return undefined;
                        }

                        return getItem(
                            department.name,
                            this.updateDepartment.bind(this, departmentId),
                            this.state.departmentId === departmentId,
                            headers.departments[departmentId],
                        );
                    })
                    .filter((item) => !!item)
                    .sort((a, b) => a.name > b.name ? 1 : -1)
                : [];

            items.push(
                getCategory(
                    localize("Avdelningar"), 
                    departments.length === 0
                        ? []
                        : [
                            getItem(
                                allText,
                                this.updateDepartment.bind(this, undefined),
                                this.state.departmentId === undefined,
                                totalCount,
                            )
                        ].concat(departments),
                        prevItems.find(cat => cat.name === localize("Avdelningar"))?.isExpanded ?? false,
                ),
            );
        }

        return items;
    }

    protected getGeneralResultsTotal(): number {
        if (!this.props.headers) {
            return 0;
        }

        let totalCount = this.props.headers.totalCount;
        if (this.props.headers.objectTypes[1]) {
            totalCount -= this.props.headers.objectTypes[1];
        }

        if (this.props.headers.objectTypes[70]) {
            totalCount -= this.props.headers.objectTypes[70];
        }

        return totalCount;
    }

    protected onDepartmentsLoaded(response: AxiosResponse<Spintr.IDepartment[]>): void {
        if (response === null) {
            return;
        }

        this.setState({
            departments: response.data,
            isLoadingDepartments: false,
        });
    }

    protected onLoadMoreClick(): void {
        this.executeSearch(this.props.results!.length);
    }

    protected onResultClick(item: Spintr.ISearchResult): void {
        if (!item || !this.props.lastParams || !this.props.headers) {
            return;
        }

        const feedback: ISearchFeedback = {
            objectId: item.id,
            query: this.props.lastParams.query,
            totalHits: this.props.headers.totalCount
        };

        submitFeedback(feedback)
        this.props.clearSearchResults();
    }

    protected onToggleOffice365(): void {
        this.setState({
            showOffice365: !this.state.showOffice365,
        });
    }

    protected onOffice365Fetched(envelopes: Spintr.IOffice365FileSearchEnvelope[]): void {
        if (envelopes === null || this.isUnmounted) {
            // we caught a HTTP error
            return;
        }
        const oneDrive = envelopes.find((envelope) => envelope.serviceType === 1);
        const sharepoint = envelopes.find((envelope) => envelope.serviceType === 2);
        
        this.setState(
            {
                isLoadingOffice365: false,
                oneDriveResults: oneDrive
                    ? {
                        ...oneDrive,
        
                        items: (this.state.oneDriveResults?.items || []).concat(oneDrive.items),
                    }
                    : this.state.oneDriveResults,
                sharepointResults: sharepoint
                    ? {
                        ...sharepoint,
        
                        items: (this.state.sharepointResults?.items || []).concat(sharepoint.items),
                    }
                    : this.state.sharepointResults
            }
        );
    }

    protected renderItem(item: Spintr.ISearchResult): ReactNode {
        return (
            <SearchResultObjectHit 
                item={item}
                onClick={this.onResultClick}
            />
        );
    }

    protected renderOffice365Item(item: Spintr.IOffice365FileSearchItem, index: number): ReactNode {
        if (!this.state.isLoadingOffice365 && (this.state.oneDriveResults?.hasMore || this.state.sharepointResults?.hasMore)) {
            const data = (this.state.sharepointResults?.items || [])
                .concat(this.state.oneDriveResults?.items || []);

            if (index === (data.length - 1)) {
                this.executeOffice365Search();
            }
        }

        const parts : Spintr.ISearchResultPath[] = [];

        let ref: Spintr.IOffice365DriveItem | undefined = item.spintrDriveItem.parentReference;
        while (ref) {
            if (!ref.name) {
                break;
            }

            parts.unshift({
                name: ref.name,
                url: ref.webUrl,
            });

            ref = ref.parentReference;
        }

        return (
            <SearchResultObjectHit 
                officeItem={item}
                onClick={this.onResultClick}
            />
        );
    }

    protected clickedUser(userId: number) {
        const feedback: ISearchFeedback = {
            objectId: userId,
            query: this.props.lastParams.query,
            totalHits: this.props.headers.totalCount
        };

        submitFeedback(feedback)
    }

    protected renderUser(user: Spintr.ISearchResult): ReactNode {
        return (
            <div className="user-hit">
                <SpintrUser
                    onClick={() => {this.clickedUser(user.id)}}
                    imageUrl={user.imageUrl}
                    name={user.name}
                    nameLink={user.url}
                    subText={user.caption}
                    personalName
                />
            </div>
        );
    }

    protected updateApp(application: SpintrTypes.SpintrApp): void {
        this.setState(
            { application },
            () => {
                if (this.submenuRef.current) {
                    this.submenuRef.current.fetch(true);
                }

                this.executeSearch();
            }
        );
    }

    protected updateDepartment(departmentId?: number): void {
        this.setState(
            { departmentId },
            () => {
                if (this.submenuRef.current) {
                    this.submenuRef.current.fetch(true);
                }

                this.executeSearch();
            }
        );
    }

    protected updateFileType(fileType: SearchFileType): void {
        this.setState(
            { 
                fileType,
                objectType: !!fileType 
                    ? SpintrTypes.UberType.File
                    : this.state.objectType
            },
            () => {
                if (this.submenuRef.current) {
                    this.submenuRef.current.fetch(true);
                }

                this.executeSearch();
            }
        );
    }

    protected updateObjectType(objectType?: SpintrTypes.UberType): void {
        this.setState(
            { objectType },
            () => {
                if (this.submenuRef.current) {
                    this.submenuRef.current.fetch(true);
                }

                this.executeSearch();
            }
        );

    }

    protected updateSorting(sorting: SearchSorting): void {
        this.setState(
            { sorting },
            () => {
                if (this.submenuRef.current) {
                    this.submenuRef.current.fetch(true);
                }

                this.executeSearch();
            }
        );
    }

    protected updateTimeSpan(timeSpan: SearchTimeSpan): void {
        this.setState(
            { timeSpan },
            () => {
                if (this.submenuRef.current) {
                    this.submenuRef.current.fetch(true);
                }

                this.executeSearch();
            }
        );
    }
}

export default connect<IStateProps, IDispatchProps, any, Spintr.AppState>(
    (state) => ({
        enabledApps: state.app.items.filter(
            (app) => app.enabled
        ),
        errorMessageTag: state.search.errorMessageTag,
        headers: state.search.currentHeaders,
        isLoading: state.search.isLoadingResults,
        lastParams: state.search.lastSuccessfulParams,
        office365Enabled: (
            state.instance.get("office365Enabled") as boolean
            &&
            state.profile.active.office365Connected
        ),
        results: state.search.currentResults,
        enableSharepoint: state.instance.get("enableSharepoint"),
        enableOnedrive: state.instance.get("enableOnedrive"),
    }),
    { clearSearchResults, executeSearchAction, setDisplayHeaderSearch, setDisableWrapperBackground, setOverlayId },
)(withRouter(SearchResultView))