import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { localize } from "src/l10n";
import { UserHovercard } from "src/ui/components";
import decodeHtmlEntities from "./decodeHtmlEntities";
import { DirectionalHint, TooltipHost } from "@fluentui/react";

type TextProcessorToken = JSX.Element | string;
type TextProcessor = (input: TextProcessorToken[]) => TextProcessorToken[];

function tokenIsText(token: TextProcessorToken): token is string {
    return typeof token === "string";
}


function createArticleNumberParser(
    canAddProducts: boolean,
    linkedProducts: Spintr.ISocialProduct[],
    ...patterns: RegExp[]
): TextProcessor {
    return function parseArticleNumbers(input: TextProcessorToken[]): TextProcessorToken[] {
        return patterns.reduce<TextProcessorToken[]>(
            (tokens, pattern) => tokens.flatMap((token, tokenIndex) => {
                if (!tokenIsText(token)) {
                    return token;
                }

                if (!token.match(pattern)) {
                    return token;
                }

                return token.split(pattern).map((part, partIndex) => {
                    if (!part.match(pattern)) {
                        return part;
                    }

                    const key = `article-${tokenIndex}-${partIndex}`;
                    const product = linkedProducts.find((product) => product.articleId.toLowerCase() === part.toLowerCase());
                    
                    if (product) {
                        const tooltipContent = (
                            <span className="interactive-product-link-tooltip">
                                {product.name}
                            </span>
                        );

                        return (
                            <Link
                                key={key}
                                to={`/products/${product.Id}`}
                            >
                                <TooltipHost
                                    calloutProps={{
                                        directionalHint: DirectionalHint.bottomCenter,
                                    }}
                                    content={tooltipContent}
                                >
                                    <span className="product-link">{part}</span>
                                </TooltipHost>
                            </Link>
                        );
                    }

                    if (!canAddProducts) {
                        return <span className="product-link disabled" key={key}>{part}</span>;
                    }

                    const encodedSKU = encodeURIComponent(part);

                    const tooltipContent = (
                        <span className="interactive-product-link-tooltip">
                            + {localize("ADD_THIS_TO_PRODUCTS")}
                        </span>
                    );

                    return (
                        <Link
                            key={key}
                            to={`/admin/products?create=${encodedSKU}`}
                        >
                            <TooltipHost
                                calloutProps={{
                                    directionalHint: DirectionalHint.bottomCenter,
                                }}
                                content={tooltipContent}
                            >
                                <span className="product-link">{part}</span>
                            </TooltipHost>
                        </Link>
                    );
                });
            }),
            input,
        );
    };
}

function parseHashtags(input: TextProcessorToken[]): TextProcessorToken[] {
    const hashtagPattern = /(?:^|\B)(#[^(?!.:?\/\)\()\s]+)/g;

    return input.flatMap((token) => {
        if (!tokenIsText(token)) {
            return token;
        }
        
        if (!token.match(hashtagPattern)) {
            return token;
        }
        
        return token.split(hashtagPattern).map((part, index) => {
            if (!part.startsWith("#")) {
                return part;
            }

            const key = `hashtag-${index}`;

            return (
                <a className="notranslate" href={"/search?q=" + encodeURIComponent(part)} key={key}>
                    {part}
                </a>
            );
        });
    });
};

function parseLinks(input: TextProcessorToken[]): TextProcessorToken[] {
    const urlPattern = /((?:(?:www\.|(?:http|https|ftp|news|file)+\:\/\/)[&#95;.a-z0-9-_]+(?:\.)?[a-z0-9\/&#95;:@=.+?!,##%&()~_\[\]-]*[^.|'|\# |!|\(|?|,| |>|<|;|\)]))/gmi;

    return input.flatMap((token) => {
        if (!tokenIsText(token)) {
            return token;
        }
        
        if (!token.match(urlPattern)) {
            return token;
        }
        
        return token.split(urlPattern).map((part, index) => {
            if (!part || !part.match(urlPattern)) {
                return part;
            }

            const url = part.startsWith("www.") ? "http://" + part : part;
            const key = `link-${index}`;

            return (
                <a className="notranslate" href={url} key={key} target="_blank" title={localize("BesokLank")}>
                    {part}
                </a>
            );
        });
    });
};

function parseMentions(input: TextProcessorToken[]): TextProcessorToken[] {
    const splitPattern = /(\[link@(?:.+?)@link\](?:.+?)\[\/link])/ig;
    const matchPattern = /\[link@(.+?)@link\](.+?)\[\/link]/ig;

    return input.flatMap((token) => {
        if (!tokenIsText(token)) {
            return token;
        }
        
        if (!token.match(matchPattern)) {
            return token;
        }

        return token.split(splitPattern).map((part, index) => {
            const match = matchPattern.exec(part);
            if (!match) {
                return part;
            }

            const url = match[1];
            const text = match[2];
            const isUser = url.indexOf('/goto/1/') > -1;
            const key = `mention-${index}`;

            if (isUser) {
                const userId = parseInt(url.split('/').pop(), 10);
    
                return (
                    <UserHovercard key={key} userId={userId}>
                        <Link to={url}>{decodeHtmlEntities(text)}</Link>
                    </UserHovercard>
                )
            }
    
            return (
                <Link key={key} to={url}>
                    {decodeHtmlEntities(text)}
                </Link>
            );
        });
    });
}

function convertTextTokensToHtml(input: TextProcessorToken[]): JSX.Element[] {
    return input.map((token, index) => {
        if (!tokenIsText(token)) {
            return token;
        }

        const key = `plaintext-${index}`;

        return (
            <span
                dangerouslySetInnerHTML={{ __html: token }} 
                key={key}
            />
        );
    });
}

function cleanText(text: string) {
    return  decodeHtmlEntities(text)
        .replace(new RegExp('&lt;br /&gt;', 'ig'), '<br />')
        .replace(new RegExp('&lt;br />', 'ig'), '<br />');   
}

export function stripInteractiveText(text: string): string {
    const matchPattern = /\[link@(.+?)@link\](.+?)\[\/link]/ig;

    return text.replace(matchPattern, (_, __, inner) => {
        return inner;
    })
}

type ProductLinkOptions = {
    enabled:            boolean;
    canAddProducts?:    boolean | undefined;
    patterns?:          RegExp[] | undefined;
    linkedProducts?:    Spintr.ISocialProduct[] | undefined
}

export type InteractiveTextFormatOptions = {
    products?:      ProductLinkOptions | undefined;
}

export default function formatInteractiveText(text: string, options?: InteractiveTextFormatOptions | undefined) {
    const parsers: TextProcessor[] = [
        parseLinks,
        parseHashtags,
        parseMentions,
    ];

    if (options?.products?.enabled) {
        parsers.push(
            createArticleNumberParser(
                options.products.canAddProducts,
                options.products.linkedProducts || [],
                ...(options.products.patterns || [/(\d{5}\-\d{1})/gmi])
            ),
        );
    }

    const children = convertTextTokensToHtml(parsers.reduce((tokens, parser) => parser(tokens), [cleanText(text)]));

    return (
        <Fragment children={children} />
    );
}
