import { AxiosResponse } from "axios";
import api from "src/spintr/SpintrApi";

const baseUrl = "/api/v1/calendarevent";

export type CalendarEventSources = "outlook";

export const fetchSpintrEvent = async (eventId: number): Promise<Spintr.ICalendarEvent> => {
    const response = await api.get<Spintr.ICalendarEvent>(`${baseUrl}/${eventId}`);
    let event = response.data;
    event.start = new Date(event.start);
    event.end = new Date(event.end);

    if (event.registrationClose) {
        event.registrationClose = new Date(event.registrationClose);
    }

    return event;
};

export const fetchEvent = async (params: any): Promise<Spintr.ICalendarEvent> => {
    var event;

    if (params.exchangeId) {
        const response = await api.get<Spintr.ICalendarEvent>(`${baseUrl}/outlook`, {
            params: { id: params.exchangeId, outlookName: params.outlookName, externalId: params.externalId },
        });

        event = response.data;
        event.start = new Date(event.start);
        event.end = new Date(event.end);

        // kanske?
        // if (event.registrationClose) {
        //     event.registrationClose = new Date(event.registrationClose);
        // }
    } else if (params.googleId) {
        event = params;
        event.start = new Date(event.start);
        event.end = new Date(event.end);
    } else {
        const response = await api.get<Spintr.ICalendarEvent>(`${baseUrl}/${params.id}`);

        event = response.data;
        event.start = new Date(event.start);
        event.end = new Date(event.end);

        if (event.registrationClose) {
            event.registrationClose = new Date(event.registrationClose);
        }
    }

    event.eventHasExpired = event.end && new Date() > event.end;
    event.participants = event.attendees.concat(event.invited, event.rejected);

    return event;
};

export const fetchExternalEvent = async (
    source: CalendarEventSources,
    eventId: string
): Promise<Spintr.ICalendarEvent> => {
    const response = await api.get<Spintr.ICalendarEvent>(`${baseUrl}/${source}/${eventId}`);

    return response.data;
};

export const joinEvent = async (uberId: number, eventId: string | number, isGuest: boolean): Promise<AxiosResponse> => {
    const eventGuest = { id: uberId, eventId, status: 1 };

    if (!isGuest) {
        let response = await api.post("/api/v1/calendareventguest", eventGuest);
        return response;
    } else {
        let response = await api.put(`/api/v1/calendareventguest/${eventId}`, eventGuest);
        return response;
    }
};

export const leaveEvent = async (uberId: number, eventId: number): Promise<AxiosResponse> => {
    const eventGuest = { id: uberId, eventId, status: 2 };

    const response = await api.put(`/api/v1/calendareventguest/${eventId}`, eventGuest);
    return response;
};

export const joinRejectExchangeEvent = async (exchangeId: string, join: boolean, externalId: string): Promise<any> => {
    const eventGuest = { exchangeId, join, externalId };

    await api.put(`/api/v1/calendareventguest/outlook`, eventGuest);
};

export const joinRejectGoogleEvent = async (googleId: string, join: boolean): Promise<any> => {
    const eventGuest = { googleId, join };

    await api.put(`/api/v1/calendareventguest/google`, eventGuest);
};

export const fetchUserCalendars = async (): Promise<Spintr.ICurrentUserCalendar[]> => {
    const response = await api.get(`/api/v1/calendar/currentuser`);

    return response.data;
};

export const respondToInvitation = async (eventId: number, status: number): Promise<void> => {
    await api.put(`/api/v1/eventinvites/${eventId}`, status, { headers: { "Content-Type": "application/json" } });
};

export const createCalendarEvent = async (event) => {
    await api.post(baseUrl, event);
};

export const toggleEventDeleted = async (event) => {
    if (event.exchangeId) {
        await api.put("/api/v1/calendarevent/deleteexchangeevent", JSON.stringify(event.exchangeId), { headers: { "Content-Type": "application/json" } });
    } else if (event.googleId) {
        await api.put("/api/v1/calendarevent/deletegoogleevent", JSON.stringify(event.exchangeId), { headers: { "Content-Type": "application/json" } });
    } else {
        await api.put(`/api/v1/calendarevent/${event.id}/toggledelete`);
    }
};

export const sendInvites = async (uberIdList: any[], eventId: any, exchangeId: string) => {
    const invites = uberIdList.map((uberId) => ({
        id: uberId,
        eventId,
        exchangeId,
    }));

    await api.post("/api/v1/calendareventguest/multiinvite", invites);
};

export const mirrorToOutlook = async (id: number) => {
    try {
        api.put(`/api/v1/calendarevent/${id}/export-to-outlook`);
    } catch (err) {
        return false;
    }

    return true;
};

export const CalendarAPI = {
    fetchExternalEvent,
    fetchSpintrEvent,
    fetchEvent,
    respondToInvitation,
    createCalendarEvent,
    fetchUserCalendars,
    joinEvent,
    leaveEvent,
    joinRejectExchangeEvent,
    joinRejectGoogleEvent,
    toggleEventDeleted,
    sendInvites,
    mirrorToOutlook,
};
export default CalendarAPI;
